<template>
  <v-app :style="colorVapp">
    <div>
    <onebox_toolbar
      :quicksearch="true"
      :parentfolder="parentfolder"
      @inputdata="filteredItems"
      @loadfile="loadfolder()"
      @callparentfolder="sendParentfolder()"
      @callstorage="loadstorage()"
    ></onebox_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <!-- <v-divider></v-divider> -->
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <br />
          <br />
          <span :style="colorProgress">&nbsp; &nbsp; {{ textdownload }}</span>
        </v-overlay>
        <!-- <v-divider></v-divider> -->
        <!-- หน้าจอปกติ -->
        <v-list nav dense :color="color.BG" v-if="resolutionScreen > 500">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center pt-1">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">delete</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="ml-n2 pt-2 pb-1">
                <!-- <span
                  v-if="$route.query.type === 2"
                  style="color: #1565C0;  font-size: 18px; font-weight: 600;"
                >{{ $t('toolbar.myinbox.Businessname')}}</span>
                <span
                  v-else
                  style="color: #1565C0;  font-size: 18px; font-weight: 600;"
                >{{ $t('toolbar.myinbox.Personalname')}}</span>-->
                <v-btn rounded text disabled>
                  <span :style="headerPage">{{$t('toolbar.myoutbox.Trashoutbox')}}</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!-- หน้าจอมือถือ -->
        <v-list nav dense :color="color.BG" class="px-4" v-else>
          <v-card
          rounded="xl"
          class="elevation-0 d-flex align-center"
          height="40"
        >
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-icon dark size="24" :color="color.theme">delete</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="pt-1">
                <span :style="headerPage" 
                  >{{$t('toolbar.myoutbox.Trashoutbox')}}</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        </v-list>

        <!-- <div id="demo"> -->
          <v-card class="elevation-0" :color="color.BG" id="thiscontainer_trashoutbox">
          <v-card-text class="pa-0">
            <v-container  fluid>
            <v-layout row wrap justify-center>
              <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                <div class="text-center">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>
              </v-flex>
              <v-flex lg12 class="pa-4" v-else>
                <v-layout row wrap class="pl-3 pr-3" v-if="resolutionScreen >= 500">
                  <!-- search ค้นหา-->
                  <!-- <v-flex xs12 lg12 class="mb-4">
                    <v-card class="elevation-0">
                      <v-layout row wrap fill-height justify-center>
                        <v-flex xs12 sm12 md12 lg12 class="pt-6">
                          <v-container fluid>
                            <v-layout>
                              <v-flex xs12 lg4 class="pl-2">
                                <v-text-field outlined dense v-model="textsearchoutbox" label="ค้นหาเลขที่เอกสาร" clearable @click:clear="loadfiles(),textsearchoutbox=''"></v-text-field>
                              </v-flex>
                              <v-flex xs2 lg4 class="pl-1 pr-5 text-left">
                                <v-btn
                                  :color="color.theme"
                                  :text-color="color.chipText"
                                  class="white--text"
                                  @click="loadfiles()"
                                  >{{
                                    $t("statusfileexpired.expired_search")
                                  }}</v-btn
                                >
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex> -->
                  <!-- ค้นหาแบบใหม่ -->
                  <!-- หน้าจอปกติ -->
                      <v-expansion-panels v-model="panel" multiple outlined>
                          <v-expansion-panel class="pa-1">
                                      <v-expansion-panel-header>
                                        <div>
                                        <span :style="headerPage">{{
                                          $t("dcmPage.conditionFile")
                                        }}</span>
                                        <p class="ml-2 mt-2" style="color:#E70034">( {{ $t("conditions") }} {{ $t("textconditions") }} )</p>
                                        </div>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content class="pa-1">
                                        <v-form>
                                          <v-layout row wrap fill-height justify-center>
                                            <v-flex xs12 sm12 md5 lg5 class="pa-1">
                                            <v-container fluid>
                                              <v-layout row wrap justify-center>
                                                <!-- ช่องกรอก เอกสารลงวันที่ -->
                                                <v-flex xs12 sm12 md6 lg6>
                                                  <v-dialog
                                                    ref="dialogFromdatedocument"
                                                    v-model="modalFromdatedocument"
                                                    :return-value.sync="fromdatedocument"
                                                    persistent
                                                    full-width
                                                    width="290px"
                                                    :color="color.theme"
                                                  >
                                                    <template v-slot:activator="{ on }">
                                                      <v-text-field
                                                        :color="color.theme"
                                                        :label="
                                                          $t(
                                                            'myinboxPage.conditionOptional.fromdatedocument'
                                                          )
                                                        "
                                                        v-model="fromdatedocument"
                                                        @click:prepend="
                                                          modalFromdatedocument = true
                                                        "
                                                        prepend-icon="event"
                                                        v-on="on"
                                                        readonly
                                                        outlined
                                                        dense
                                                        clearable
                                                        :persistent-hint="fromdate_hint"
                                                        @click:clear="todatedocument = ''"
                                                        class="my-hint-style pa-1 pt-0 pb-0"
                                                      ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                      v-model="fromdatedocument"
                                                      scrollable
                                                      :locale="$t('default')"
                                                      @input="changeFromdatedocument()"
                                                      :color="color.theme"
                                                      
                                                      :max="todatedocument"
                                                      >
                                                      <v-spacer></v-spacer>
                                                      <v-btn
                                                        dark
                                                        color="error"
                                                        @click.stop="
                                                          modalFromdatedocument = false
                                                        "
                                                        >{{
                                                          $t(
                                                            "myinboxPage.conditionOptional.closefromdatedocument"
                                                          )
                                                        }}</v-btn
                                                      >
                                                    </v-date-picker>
                                                  </v-dialog>
                                                </v-flex>

                                                <!-- ช่องกรอก เอกสารลงวันที่ -->
                                                <v-flex xs12 sm12 md6 lg6>
                                                  <v-dialog
                                                    ref="dialogTodatedocument"
                                                    v-model="modalTodatedocument"
                                                    :return-value.sync="todatedocument"
                                                    persistent
                                                    full-width
                                                    width="290px"
                                                    :color="color.theme"
                                                  >
                                                    <template v-slot:activator="{ on }">
                                                      <v-text-field
                                                        readonly
                                                        :color="color.theme"
                                                        :label="
                                                          $t(
                                                            'myinboxPage.conditionOptional.todatedocument'
                                                          )
                                                        "
                                                        v-model="todatedocument"
                                                        prepend-icon="event"
                                                        @click:prepend="
                                                          modalTodatedocument = true
                                                        "
                                                        v-on="on"
                                                        outlined
                                                        dense
                                                        clearable
                                                        :persistent-hint="todate_hint"
                                                        class="my-hint-style pa-1 pt-0 pb-0"
                                                        :disabled="fromdatedocument === '' || fromdatedocument === null"
                                                      ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                      v-model="todatedocument"
                                                      scrollable
                                                      :locale="$t('default')"
                                                      @input="
                                                        $refs.dialogTodatedocument.save(
                                                          todatedocument
                                                        )
                                                      "
                                                      :min="fromdatedocument"
                                                      :max="setdate(fromdatedocument)"
                                                      :color="color.theme"
                                                      >
                                                      <v-spacer></v-spacer>
                                                      <v-btn
                                                        dark
                                                        color="error"
                                                        @click.stop="
                                                          modalTodatedocument = false
                                                        "
                                                        >{{
                                                          $t(
                                                            "myinboxPage.conditionOptional.closetodatedocument"
                                                          )
                                                        }}</v-btn
                                                      >
                                                    </v-date-picker>
                                                  </v-dialog>
                                                </v-flex>
                                              </v-layout>

                                              <v-layout row wrap justify-center>
                                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                                <v-flex xs12 sm12 md6 lg6>
                                                  <v-dialog
                                                    ref="dialogFromdateupload"
                                                    v-model="modalFromdateupload"
                                                    :return-value.sync="fromdateupload"
                                                    persistent
                                                    full-width
                                                    width="290px"
                                                    :color="color.theme"
                                                  >
                                                    <template v-slot:activator="{ on }">
                                                      <v-text-field
                                                        clearable
                                                        :color="color.theme"
                                                        :label="
                                                          $t(
                                                            'myoutboxPage.conditionOptional.fromdateupload'
                                                          )
                                                        "
                                                        v-model="fromdateupload"
                                                        @click:prepend="
                                                          modalFromdateupload = true
                                                        "
                                                        prepend-icon="event"
                                                        v-on="on"
                                                        readonly
                                                        outlined
                                                        dense
                                                        @click:clear="todateupload = ''"
                                                        class="my-hint-style pa-1 pt-0 pb-0"
                                                      ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                      v-model="fromdateupload"
                                                      scrollable
                                                      :locale="$t('default')"
                                                      @input="changeFromdateupload()"
                                                      :color="color.theme"
                                                      
                                                      :max="todateupload"
                                                    >
                                                      <v-spacer></v-spacer>
                                                      <v-btn
                                                        dark
                                                        color="error"
                                                        @click.stop="
                                                          modalFromdateupload = false
                                                        "
                                                        >{{
                                                          $t(
                                                            "myinboxPage.conditionOptional.closefromdateupload"
                                                          )
                                                        }}</v-btn
                                                      >
                                                    </v-date-picker>
                                                  </v-dialog>
                                                </v-flex>
                                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                                <v-flex xs12 sm12 md6 lg6>
                                                  <v-dialog
                                                    ref="dialogTodateupload"
                                                    v-model="modalTodateupload"
                                                    :return-value.sync="todateupload"
                                                    persistent
                                                    full-width
                                                    width="290px"
                                                    :color="color.theme"
                                                  >
                                                    <template v-slot:activator="{ on }">
                                                      <v-text-field
                                                        clearable
                                                        readonly
                                                        :color="color.theme"
                                                        :label="
                                                          $t(
                                                            'myoutboxPage.conditionOptional.todateupload'
                                                          )
                                                        "
                                                        v-model="todateupload"
                                                        prepend-icon="event"
                                                        @click:prepend="
                                                          modalTodateupload = true
                                                        "
                                                        v-on="on"
                                                        outlined
                                                        dense
                                                        class="my-hint-style pa-1 pt-0 pb-0"
                                                        :disabled="fromdateupload === '' || fromdateupload === null"
                                                      ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                      v-model="todateupload"
                                                      scrollable
                                                      :locale="$t('default')"
                                                      @input="
                                                        $refs.dialogTodateupload.save(
                                                          todateupload
                                                        )
                                                      "
                                                      :min="fromdateupload"
                                                      :max="setdate(fromdateupload)"
                                                      :color="color.theme"
                                                    >
                                                      <v-spacer></v-spacer>
                                                      <v-btn
                                                        dark
                                                        color="error"
                                                        @click.stop="
                                                          modalTodateupload = false
                                                        "
                                                        >{{
                                                          $t(
                                                            "myinboxPage.conditionOptional.closetodateupload"
                                                          )
                                                        }}</v-btn
                                                      >
                                                    </v-date-picker>
                                                  </v-dialog>
                                                </v-flex>
                                              </v-layout>
                                              <v-layout row wrap justify-center>
                                                <!-- ช่องกรอก ผู้รับ -->
                                                <v-flex xs12 sm12 md6 lg6>
                                                  <v-text-field
                                                    outlined
                                                    dense
                                                    clearable
                                                    class="pa-1 pt-0 pb-0"
                                                    v-model="receivername"
                                                    :label="
                                                      $t(
                                                        'myinboxPage.conditionOptional.receivername'
                                                      )
                                                    "
                                                    prepend-icon="account_box"
                                                    :color="color.theme"
                                                  ></v-text-field>
                                                </v-flex>
                                                <!-- ช่องเลือก สถานะส่ง onechat -->
                                                <v-flex xs12 sm12 md6 lg6>
                                                  <v-select
                                                    clearable
                                                    outlined
                                                    dense
                                                    class="pa-1 pt-0 pb-0"
                                                    v-model="statusonechat"
                                                    :items="liststatusonechat"
                                                    item-value="status_code"
                                                    item-text="status_text"
                                                    :label="
                                                      $t(
                                                        'myoutboxPage.conditionOptional.statussendonechat'
                                                      )
                                                    "
                                                    prepend-icon="line_style"
                                                    :item-color="color.theme"
                                                    multiple
                                                  >
                                                    <template v-slot:prepend-item>
                                                      <v-list-item
                                                        ripple
                                                        @click="fn_selectallstatusonechat"
                                                      >
                                                        <v-list-item-action>
                                                          <v-icon
                                                            :color="
                                                              statusonechat.length > 0
                                                                ? 'indigo darken-4'
                                                                : ''
                                                            "
                                                          >
                                                            {{ icon_all }}
                                                          </v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                          <v-list-item-title>
                                                            Select All
                                                          </v-list-item-title>
                                                        </v-list-item-content>
                                                      </v-list-item>
                                                      <v-divider class="mt-2"></v-divider>
                                                    </template>
                                                    <template
                                                      v-slot:selection="{ item, index }"
                                                    >
                                                      <span v-if="index < maxDisplay">{{
                                                        item.status_text
                                                      }}</span>
                                                      <span
                                                        v-if="index === maxDisplay"
                                                        class="grey--text caption"
                                                        >(+{{
                                                          statusonechat.length - maxDisplay
                                                        }})</span
                                                      >
                                                    </template>
                                                  </v-select>
                                                </v-flex>
                                              </v-layout>

                                              <v-layout row wrap justify-center>
                                                <!-- ช่องกรอก ผู้ส่ง -->
                                                <v-flex xs12 sm12 md6 lg6>
                                                  <v-text-field
                                                    outlined
                                                    dense
                                                    clearable
                                                    class="pa-1 pt-0 pb-0"
                                                    v-model="sendername"
                                                    :label="
                                                      $t(
                                                        'myinboxPage.conditionOptional.sendername'
                                                      )
                                                    "
                                                    prepend-icon="account_circle"
                                                    :color="color.theme"
                                                  ></v-text-field>
                                                </v-flex>
                                                <!-- ประเภท ผู้รับ -->
                                                <v-flex xs12 sm12 md6 lg6>
                                                  <v-select
                                                    clearable
                                                    outlined
                                                    dense
                                                    class="pa-1 pt-0 pb-0"
                                                    v-model="oneboxtype"
                                                    :items="boxtype"
                                                    item-text="box_type"
                                                    item-value="box_value"
                                                    :label="
                                                      $t(
                                                        'myoutboxPage.conditionOptional.receivernameextension'
                                                      )
                                                    "
                                                    prepend-icon="line_style"
                                                    :color="color.theme"
                                                    :item-color="color.theme"
                                                    multiple
                                                  >
                                                    <template v-slot:prepend-item>
                                                      <v-list-item
                                                        ripple
                                                        @click="fn_selectall_boxtype"
                                                      >
                                                        <v-list-item-action>
                                                          <v-icon
                                                            :color="
                                                              documenttype.length > 0
                                                                ? 'indigo darken-4'
                                                                : ''
                                                            "
                                                          >
                                                            {{ icon_ }}
                                                          </v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                          <v-list-item-title>
                                                            Select All
                                                          </v-list-item-title>
                                                        </v-list-item-content>
                                                      </v-list-item>
                                                      <v-divider class="mt-2"></v-divider>
                                                    </template>
                                                  </v-select>
                                                </v-flex>
                                              </v-layout>
                                              <!-- ช่องเลือก ประเภทเอกสาร -->
                                              <v-layout row wrap justify-center>
                                                <v-flex xs12 sm12 md12 lg12>
                                                  <v-select
                                                    clearable
                                                    outlined
                                                    dense
                                                    class="pa-1 pt-0 pb-0"
                                                    v-model="documenttype"
                                                    :items="listDoctype"
                                                    item-value="document_type_code"
                                                    :item-text="$t('default') === 'th' ? 'document_type_th' : 'document_type_eng'"
                                                    :label="
                                                      $t(
                                                        'myinboxPage.conditionOptional.doctype'
                                                      )
                                                    "
                                                    prepend-icon="line_style"
                                                    :color="color.theme"
                                                    :item-color="color.theme"
                                                    multiple
                                                  >
                                                    <template v-slot:prepend-item>
                                                      <v-list-item
                                                        ripple
                                                        @click="fn_selectall"
                                                      >
                                                        <v-list-item-action>
                                                          <v-icon
                                                            :color="
                                                              documenttype.length > 0
                                                                ? 'indigo darken-4'
                                                                : ''
                                                            "
                                                          >
                                                            {{ icon }}
                                                          </v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                          <v-list-item-title>
                                                            Select All
                                                          </v-list-item-title>
                                                        </v-list-item-content>
                                                      </v-list-item>
                                                      <v-divider class="mt-2"></v-divider>
                                                    </template>
                                                    <template
                                                      v-slot:selection="{ item, index }"
                                                    >
                                                      <span v-if="index < maxDisplay"
                                                        >{{ $t('default') === 'th' ? item.document_type_th : item.document_type_eng }} ,
                                                      </span>
                                                      <span
                                                        v-if="index === maxDisplay"
                                                        class="grey--text caption"
                                                        >(+{{
                                                          documenttype.length - maxDisplay
                                                        }}
                                                        ประเภท)</span
                                                      >
                                                    </template>
                                                  </v-select>
                                                </v-flex>
                                              </v-layout>
                                            </v-container>
                                          </v-flex>

                                            <div>
                                              <div
                                                class="pt-6"
                                                @mouseover="show_info_doc = true"
                                                @mouseleave="show_info_doc = false"
                                                fab
                                                x-small
                                                
                                              >
                                                <v-icon color="primary">mdi-information</v-icon>
                                                <v-badge
                                                  v-if="$t('default') === 'th'"
                                                  style="z-index:10000;bottom:-30px;left:-35px;"
                                                  :value="show_info_doc"
                                                  :content="$t('balloon.info_date_doc')"
                                                  transition="slide-y-transition"
                                                ></v-badge>
                                                <v-badge
                                                  v-else
                                                  style="z-index:10000;bottom:-30px;left:-27px;"
                                                  :value="show_info_doc"
                                                  :content="$t('balloon.info_date_doc')"
                                                  transition="slide-y-transition"
                                                ></v-badge>
                                              </div>
                                              <div
                                                class="pt-10"
                                                @mouseover="show_info = true"
                                                @mouseleave="show_info = false"
                                                fab
                                                x-small
                                                
                                              >
                                                <v-icon color="primary">mdi-information</v-icon>
                                                <v-badge
                                                  v-if="$t('default') === 'th'"
                                                  style="z-index:10000;bottom:-30px;left:-35px;"
                                                  :value="show_info"
                                                  :content="$t('balloon.info_date')"
                                                  transition="slide-y-transition"
                                                ></v-badge>
                                                <v-badge
                                                  v-else
                                                  style="z-index:10000;bottom:-30px;left:-27px;"
                                                  :value="show_info"
                                                  :content="$t('balloon.info_date')"
                                                  transition="slide-y-transition"
                                                ></v-badge>
                                              </div>
                                            </div>

                                            <v-flex xs12 sm12 md1 lg1 class="text-center">
                                              <v-divider vertical></v-divider>
                                            </v-flex>

                                            <v-flex xs12 sm12 md5 lg5 class="text-center">
                                              <v-container fluid>
                                                <v-layout row wrap justify-center>
                                                  <!-- ช่องกรอกชื่อไฟล์ -->
                                                  <v-flex xs12 sm12 md6 lg6>
                                                    <v-text-field
                                                      clearable
                                                      outlined
                                                      dense
                                                      class="pa-1 pt-0 pb-0"
                                                      v-model="filename"
                                                      :label="
                                                        $t(
                                                          'myinboxPage.conditionOptional.filename'
                                                        )
                                                      "
                                                      prepend-icon="insert_drive_file"
                                                      :color="color.theme"
                                                    ></v-text-field>
                                                  </v-flex>
                                                  <!-- ช่องเลือกประเภทไฟล์ -->
                                                  <v-flex xs12 sm12 md6 lg6>
                                                    <v-select
                                                      outlined
                                                      dense
                                                      clearable
                                                      class="pa-1 pt-0 pb-0"
                                                      v-model="fileextension"
                                                      :items="listExtensionFile"
                                                      item-value="data_type"
                                                      item-text="data_type"
                                                      :label="
                                                        $t(
                                                          'myinboxPage.conditionOptional.fileextension'
                                                        )
                                                      "
                                                      prepend-icon="extension"
                                                      :color="color.theme"
                                                      :item-color="color.theme"
                                                      multiple
                                                    >
                                                      <template v-slot:prepend-item>
                                                        <v-list-item
                                                          ripple
                                                          @click="fn_selectall_fileextension"
                                                        >
                                                          <v-list-item-action>
                                                            <v-icon
                                                              :color="
                                                                fileextension.length > 0
                                                                  ? 'indigo darken-4'
                                                                  : ''
                                                              "
                                                            >
                                                              {{ icons }}
                                                            </v-icon>
                                                          </v-list-item-action>
                                                          <v-list-item-content>
                                                            <v-list-item-title>
                                                              Select All
                                                            </v-list-item-title>
                                                          </v-list-item-content>
                                                        </v-list-item>
                                                        <v-divider class="mt-2"></v-divider>
                                                      </template>
                                                      <template
                                                        v-slot:selection="{ item, index }"
                                                      >
                                                        <span
                                                          v-if="index < maxDisplayfileextension"
                                                          >{{ item.data_type }}</span
                                                        >
                                                        <span
                                                          v-if="
                                                            index === maxDisplayfileextension
                                                          "
                                                          class="grey--text caption"
                                                          >(+{{
                                                            fileextension.length -
                                                              maxDisplayfileextension
                                                          }}
                                                          )</span
                                                        >
                                                      </template>
                                                    </v-select>
                                                  </v-flex>
                                                </v-layout>
                                                <v-layout row wrap justify-center>
                                                  <v-flex xs12 sm12 md6 lg6>
                                                    <!-- ค้นหาเลขที่เอกสาร -->
                                                    <v-text-field 
                                                    @click:clear="clearDocumentId"
                                                    class="pa-1 pt-0 pb-0"
                                                    outlined 
                                                    dense 
                                                    v-model="documentid" 
                                                    :label="$t('myoutboxPage.conditionOptional.filedocumentid')" 
                                                    clearable 
                                                    prepend-icon="format_list_numbered"
                                                    ></v-text-field>
                                                  </v-flex>
                                                  <!-- ค้นหาเลขที่เอกสารถึง -->
                                                <v-flex xs12 sm12 md6 lg6>
                                                  <v-text-field
                                                    clearable
                                                    outlined
                                                    :disabled="!!!documentid"
                                                    dense
                                                    class="pa-1 pt-0 pb-0"
                                                    v-model="documentidto"
                                                    :label="
                                                      $t(
                                                        'myoutboxPage.conditionOptional.todateupload'
                                                      )
                                                    "
                                                    prepend-icon="format_list_numbered"
                                                    :color="color.theme"
                                                  ></v-text-field>
                                                </v-flex>
                                                </v-layout>

                                                <!-- ช่องกรอก SellerTaxID -->
                                                <v-layout row wrap justify-center>
                                                  <v-flex xs12 sm12 md6 lg6>
                                                    <v-text-field
                                                      clearable
                                                      outlined
                                                      dense
                                                      class="pa-1 pt-0 pb-0"
                                                      v-model="sellertaxid"
                                                      :label="
                                                        $t(
                                                          'myinboxPage.conditionOptional.sellertaxid'
                                                        )
                                                      "
                                                      prepend-icon="mdi-domain"
                                                      :color="color.theme"
                                                    ></v-text-field>
                                                  </v-flex>
                                                  <v-flex xs12 sm12 md6 lg6>
                                                    <v-text-field
                                                      clearable
                                                      outlined
                                                      dense
                                                      class="pa-1 pt-0 pb-0"
                                                      v-model="sellerbranchid"
                                                      :label="
                                                        $t(
                                                          'myinboxPage.conditionOptional.sellerbranchid'
                                                        )
                                                      "
                                                      prepend-icon="mdi-format-list-bulleted-square"
                                                      :color="color.theme"
                                                    ></v-text-field>
                                                  </v-flex>
                                                </v-layout>

                                                <!-- ช่องกรอก BuyerTaxID -->
                                                <v-layout row wrap justify-center>
                                                  <v-flex xs12 sm12 md6 lg6>
                                                    <v-text-field
                                                      clearable
                                                      outlined
                                                      dense
                                                      class="pa-1 pt-0 pb-0"
                                                      v-model="buyertaxid"
                                                      :label="
                                                        $t(
                                                          'myinboxPage.conditionOptional.buyertaxid'
                                                        )
                                                      "
                                                      prepend-icon="mdi-domain"
                                                      :color="color.theme"
                                                    ></v-text-field>
                                                  </v-flex>
                                                  <v-flex xs12 sm12 md6 lg6>
                                                    <v-text-field
                                                      clearable
                                                      outlined
                                                      dense
                                                      class="pa-1 pt-0 pb-0"
                                                      v-model="buyerbranchid"
                                                      :label="
                                                        $t(
                                                          'myinboxPage.conditionOptional.buyerbranchid'
                                                        )
                                                      "
                                                      prepend-icon="mdi-format-list-bulleted-square"
                                                      :color="color.theme"
                                                    ></v-text-field>
                                                  </v-flex>
                                                </v-layout>
                                                <!-- Po_number -->
                                                <v-layout row wrap justify-center>
                                                <v-flex xs12 sm12 md12 lg12>
                                                    <v-text-field
                                                      clearable
                                                      outlined
                                                      dense
                                                      class="pa-1 pt-0 pb-0"
                                                      v-model="ponumber"
                                                      :label="$t('myoutboxPage.conditionOptional.ponumber')"
                                                      prepend-icon="mdi-script-text-outline"
                                                      :color="color.theme"
                                                    ></v-text-field>
                                                  </v-flex>
                                                </v-layout>
                                              </v-container>
                                            </v-flex>
                                          </v-layout>
                                        </v-form>
                                      </v-expansion-panel-content>
                          </v-expansion-panel>
                      </v-expansion-panels>
                  <v-flex xs12 lg12 class="text-center pb-5">
                        <br />
                        <!-- เคลียร์ -->
                        <v-btn
                          class="ma-1 white--text"
                          :color="color.theme"
                          :disabled="loaddataprogress"
                          :loading="loaddataprogress"
                          @click="clear_data()"
                        >
                          <v-icon left>mdi-refresh</v-icon>
                          {{ $t("myinboxPage.clear_data") }}
                        </v-btn>
                        <!-- ค้นหา -->
                        <v-btn
                          class="ma-1 white--text"
                          :color="color.theme"
                          :disabled="loaddataprogress"
                          :loading="loaddataprogress"
                          @click="searchdocument()"
                        >
                          <v-icon left>search</v-icon>
                          &nbsp;
                          {{ $t("myinboxPage.search") }}
                        </v-btn>
                        <!-- ลบจาก Filter -->
                        <v-btn
                          class="ma-1 white--text"
                          color="#C51D1D"
                          :disabled="loaddataprogress"
                          :loading="loaddataprogress"
                          @click="delete_filter()"
                        >
                          <v-icon left>mdi-trash-can-outline</v-icon>
                          {{ $t("myoutboxPage.delete_filter") }}
                        </v-btn>
                        <!-- กู้คืน -->
                        <v-btn
                          class="ma-1 white--text"
                          color="#479F31"
                          :disabled="loaddataprogress"
                          :loading="loaddataprogress"
                          @click="restore_filter()"
                        >
                          <v-icon left>mdi-history</v-icon>
                          {{ $t("myoutboxPage.restore_filter") }}
                        </v-btn>
                      </v-flex>
                      <br />
                  <v-flex lg2 xs4>                   
                    <v-select
                      prepend-inner-icon="sort"
                      hide-details
                      outlined
                      solo
                      dense
                      v-model="size"
                      :items="listsize"
                      class="text-center"
                      :item-color="color.theme"
                      :color="color.theme"
                    >
                      <template v-slot:item="{ item }">
                        <div class="text-center">
                          <span style="font-size: 17px;">{{ item }}</span>
                        </div>
                      </template>
                      <template v-slot:selection="{ item }">
                        <div class="text-center">
                          <span style="font-size: 17px;">{{ item }}</span>
                        </div>
                      </template>
                    </v-select>
                    <!-- <br /> -->
                  </v-flex>
                  <v-flex lg10 xs8 class="text-right">
                    <v-chip
                      class="ma-2"
                      :color="color.theme" 
                      :text-color="color.chipText"
                    >
                      <v-icon left>folder</v-icon>
                      {{countfolders}} {{countfolder > 1 ? $t('folders'): $t('folder')}} 
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      :color="color.theme" 
                      :text-color="color.chipText"
                    >
                      <v-icon left>mdi-file</v-icon>
                      {{countfiles}} {{countfile > 1 ? $t('files'): $t('file')}}                     
                    </v-chip>
                  </v-flex>
                  <v-flex xs4 lg4>
                    <div v-if="statusmutipledelete === false" class="pt-2">
                      <v-btn
                        @mouseover="show_menu = true"
                        @mouseleave="show_menu = false"
                        :color="color.theme"
                        :dark="color.darkTheme"
                        rounded
                        class="ma-1"
                        @click="(statusmutipledelete = true), (show_menu = false), (arrayfileidselect = [])"
                        fab
                        small
                      >
                        <v-icon>delete</v-icon>&nbsp;
                        <!-- {{ $t('myinboxPage.multipledownload')}} -->
                        <v-badge v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-45px;left:-30px;"
                            :value="show_menu"
                            :content="$t('balloon.menu_trash')"
                            transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                            style="z-index:10000;bottom:-45px;left:-30px;"
                            :value="show_menu"
                            :content="$t('balloon.menu_trash')"
                            transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                    </div>
                    <div v-else class="pt-2">
                      <!-- <v-btn
                          @mouseover="show_delete_total = true"
                          @mouseleave="show_delete_total = false"
                          color="#FF0000"
                          rounded
                          class="ma-1 white--text"
                          @click="opendialogcomfirmemptytrashalloutbox = true"
                          fab
                          small
                        >
                          <v-icon>delete_forever</v-icon>&nbsp;
                          {{ $t('myinboxPage.multipledownload')}}
                          <v-badge
                            v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-45px;left:-30px;"
                            :value="show_delete_total"
                            :content="$t('balloon.permanently_delete_total')"
                            transition="slide-y-transition"
                          ></v-badge>
                          <v-badge
                            v-else
                            style="z-index:10000;bottom:-45px;left:-30px;"
                            :value="show_delete_total"
                            :content="$t('balloon.permanently_delete_total')"
                            transition="slide-y-transition"
                          ></v-badge>
                      </v-btn> -->
                      <v-btn 
                      @mouseover="show_delete = true"
                      @mouseleave="show_delete = false"
                      fab 
                      small 
                      color="#E7625F" 
                      class="ma-1 white--text" 
                      @click="fn_emptytrashinbox_selected()">
                      <!-- @click="opendialogtrue()" -->
                      
                        <v-icon>delete_forever</v-icon>
                        <v-badge v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_delete"
                              :content="$t('balloon.permanently_delete')"
                              transition="slide-y-transition"
                            ></v-badge>
                          <v-badge
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_delete"
                              :content="$t('balloon.permanently_delete')"
                              transition="slide-y-transition"
                            ></v-badge>
                      </v-btn>
                       <v-btn 
                       @mouseover="show_restore = true"
                       @mouseleave="show_restore = false"
                       fab 
                       small 
                       color="green" 
                       dark 
                       class="ma-1" 
                       @click="opendialogrestore()">
                        <v-icon>restore</v-icon>
                          <v-badge v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-25px;"
                              :value="show_restore"
                              :content="$t('balloon.restore')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge v-else
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_restore"
                              :content="$t('balloon.restore')"
                              transition="slide-y-transition"
                            ></v-badge>
                      </v-btn>
                      <v-btn
                        @mouseover="show_clear = true"
                        @mouseleave="show_clear = false"
                        fab
                        small
                        :color="color.theme"
                        :dark="color.darkTheme"
                        class="ma-1 elevation-3"
                        @click="statusmutipledelete = false, clearmultipledelete(),show_clear = false"
                      >
                        <v-icon>clear</v-icon>
                          <v-badge v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-45px;left:-20px;"
                                :value="show_clear"
                                :content="$t('balloon.clear')"
                                transition="slide-y-transition"
                            ></v-badge>
                          <v-badge v-else
                                style="z-index:10000;bottom:-45px;left:-30px;"
                                :value="show_clear"
                                :content="$t('balloon.clear')"
                                transition="slide-y-transition"
                            ></v-badge>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs8 lg8 class="text-right">
                    <!-- ปุ่มล้างถังขยะ -->
                    <v-btn
                      small
                      color="error"
                      class="ma-1 mt-6"
                      @click="checkComfirmEmptyTrashAll()"
                    >
                      <span>{{$t('balloon.permanently_delete_total')}}</span>
                    </v-btn>
                  </v-flex>
                  <!-- ปิดชื่อฟิลเตอร์ตามหัวข้อสีเขียว -->
                  <!-- <v-flex lg4 offset-lg6 class="pl-3 pr-3 hidden-md-and-down" >
                    <v-alert 
                    dense
                    height="41"
                    v-model="clickSort" 
                   :color="color.alertSort"
                    dark 
                    v-show="clickSort" 
                    style="margin-bottom: 0%;" 
                    ><div><v-icon :color="color.alertText" v-if="multiSort===true " > mdi-chevron-up</v-icon>
                    <v-icon :color="color.alertText" v-else-if="multiSort===false" > mdi-chevron-down</v-icon>
                      <span :style="colorSort" v-if="sortby === 'name'">{{$t('Filter.name')}}</span>
                      <span :style="colorSort" v-else-if="sortby === 'date'">{{$t('Filter.date')}}</span>
                      <span :style="colorSort" v-else-if="sortby === 'size'">{{$t('Filter.size')}}</span>
                      <span :style="colorSort" v-else-if="sortby === 'owner'">{{$t('Filter.owner')}}</span>
                      <v-btn 
                          width="24"
                          height="24"
                          style="float: right;" 
                          text 
                          fab 
                          x-small 
                         :color="color.alertSort" 
                          dark 
                          @click="checkSort" 
                        >
                        <v-icon :color="color.alertText"> mdi-close-circle</v-icon></v-btn>
                          </div></v-alert></v-flex>  -->
                </v-layout>
                <!-- หน้าจอxs -->
                <v-layout row wrap class="pl-3 pr-3" v-else>
                  <v-expansion-panels v-model="panel" multiple outlined>
                    <v-expansion-panel class="pa-1">
                      <v-expansion-panel-header>
                        <div>
                        <span :style="headerPage">{{
                          $t("dcmPage.conditionFile")
                        }}</span>
                        <p class="ml-2" style="color:#E70034">( {{ $t("conditions") }} {{ $t("textconditions") }} )</p>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-1">
                        <v-form>
                          <v-layout row wrap fill-height justify-center>
                            <v-flex xs12 sm12 md5 lg5 class="pa-1">
                              <v-container fluid>
                                <v-layout row wrap justify-center>
                                  <!-- ช่องกรอกชื่อไฟล์ -->
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="filename"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.filename'
                                        )
                                      "
                                      append-icon="mdi-magnify"
                                    ></v-text-field>
                                  </v-flex>
                                  <!-- ช่องเลือกประเภทไฟล์ -->
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-select
                                      outlined
                                      dense
                                      clearable
                                      class="pa-1 pt-0 pb-0"
                                      v-model="fileextension"
                                      :items="listExtensionFile"
                                      item-value="data_type"
                                      item-text="data_type"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.fileextension'
                                        )
                                      "
                                      :item-color="color.theme"
                                      multiple
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item
                                          ripple
                                          @click="fn_selectall_fileextension"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              :color="
                                                fileextension.length > 0
                                                  ? 'indigo darken-4'
                                                  : ''
                                              "
                                            >
                                              {{ icons }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <span
                                          v-if="index < maxDisplayfileextension"
                                          >{{ item.data_type }}</span
                                        >
                                        <span
                                          v-if="
                                            index === maxDisplayfileextension
                                          "
                                          class="grey--text caption"
                                          >(+{{
                                            fileextension.length -
                                              maxDisplayfileextension
                                          }}
                                          )</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs2 lg3 mt-2 d-flex>
                                    <div>
                                      {{ $t("toolbar.searching.startdate") }}
                                    </div>
                                  </v-flex>
                                  <!-- ช่องวันที่ได้รับไฟล์ -->
                                  <v-flex xs10 lg3>
                                    <v-dialog
                                      ref="dialogFromdateupload"
                                      v-model="modalFromdateupload"
                                      :return-value.sync="fromdateupload"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          clearable
                                          :color="color.theme"
                                          :label="
                                            $t(
                                              'myoutboxPage.conditionOptional.fromdateupload'
                                            )
                                          "
                                          v-model="fromdateupload"
                                          @click:prepend="
                                            modalFromdateupload = true
                                          "
                                          append-icon="event"
                                          v-on="on"
                                          readonly
                                          outlined
                                          dense
                                          @click:clear="fromdateupload = '' , todateupload = ''"
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                        ></v-text-field>
                                        <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                        <!-- <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myoutboxPage.conditionOptional.fromdateupload')"
                                        v-model="fromdateupload"
                                        @click:prepend="modalFromdateupload = true, fromdateupload = datenow_eng,on"
                                        prepend-icon="event"
                                        :placeholder="$t('myoutboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        @click:clear="todateupload = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                      </template>
                                      <v-date-picker
                                        v-model="fromdateupload"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="changeFromdateupload()"
                                        :color="color.theme"
                                        :min="setdate_(todateupload)"
                                        :max="todateupload"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click.stop="
                                            modalFromdateupload = false
                                          "
                                          >{{
                                            $t(
                                              "myoutboxPage.conditionOptional.closefromdateupload"
                                            )
                                          }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                  <!-- <v-flex mt-2 d-flex justify-center>-</v-flex> -->
                                  <v-flex xs2 lg3 mt-2 d-flex>
                                    <div>
                                      {{ $t("toolbar.searching.enddate") }}
                                    </div>
                                  </v-flex>
                                  <!-- ช่องวันที่ได้รับไฟล์ -->
                                  <v-flex xs10 lg3>
                                    <v-dialog
                                      ref="dialogTodateupload"
                                      v-model="modalTodateupload"
                                      :return-value.sync="todateupload"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          clearable
                                          readonly
                                          :color="color.theme"
                                          :label="
                                            $t(
                                              'myoutboxPage.conditionOptional.todateupload'
                                            )
                                          "
                                          v-model="todateupload"
                                          append-icon="event"
                                          @click:prepend="
                                            modalTodateupload = true
                                          "
                                          v-on="on"
                                          outlined
                                          dense
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                        ></v-text-field>
                                        <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                        <!-- <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myoutboxPage.conditionOptional.todateupload')"
                                        v-model="todateupload"
                                        prepend-icon="event"
                                        @click:prepend="modalTodateupload = true, todateupload = datenow_eng,on"
                                        :placeholder="$t('myoutboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                      </template>
                                      <v-date-picker
                                        v-model="todateupload"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="
                                          $refs.dialogTodateupload.save(
                                            todateupload
                                          )
                                        "
                                        :color="color.theme"
                                        :min="fromdateupload"
                                        :max="setdate(fromdateupload)"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click.stop="
                                            modalTodateupload = false
                                          "
                                          >{{
                                            $t(
                                              "myoutboxPage.conditionOptional.closetodateupload"
                                            )
                                          }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      outlined
                                      dense
                                      clearable
                                      class="pa-1 pt-0 pb-0"
                                      v-model="receivername"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.receivername'
                                        )
                                      "
                                      append-icon="mdi-email-receive"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <!-- ช่องเลือก สถานะส่ง onechat -->
                                    <v-select
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="statusonechat"
                                      :items="liststatusonechat"
                                      item-value="status_code"
                                      item-text="status_text"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.statussendonechat'
                                        )
                                      "
                                      :item-color="color.theme"
                                      multiple
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item
                                          ripple
                                          @click="fn_selectallstatusonechat"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              :color="
                                                statusonechat.length > 0
                                                  ? 'indigo darken-4'
                                                  : ''
                                              "
                                            >
                                              {{ icon_all }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <span v-if="index < maxDisplay">{{
                                          item.status_text
                                        }}</span>
                                        <span
                                          v-if="index === maxDisplay"
                                          class="grey--text caption"
                                          >(+{{
                                            statusonechat.length - maxDisplay
                                          }})</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <!-- ช่องกรอก ผู้ส่ง -->
                                    <v-text-field
                                      outlined
                                      dense
                                      clearable
                                      class="pa-1 pt-0 pb-0"
                                      v-model="sendername"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.sendername'
                                        )
                                      "
                                      append-icon="mdi-email-send"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-select
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="oneboxtype"
                                      :items="boxtype"
                                      item-text="box_type"
                                      item-value="box_value"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.receivernameextension'
                                        )
                                      "
                                      :color="color.theme"
                                      :item-color="color.theme"
                                      multiple
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item
                                          ripple
                                          @click="fn_selectall_boxtype"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              :color="
                                                documenttype.length > 0
                                                  ? 'indigo darken-4'
                                                  : ''
                                              "
                                            >
                                              {{ icon_ }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md12 lg12>
                                    <!-- ช่องเลือก ประเภทเอกสาร -->
                                    <v-select
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="documenttype"
                                      :items="listDoctype"
                                      item-value="document_type_code"
                                      :item-text="$t('default') === 'th' ? 'document_type_th' : 'document_type_eng'"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.doctype'
                                        )
                                      "
                                      :item-color="color.theme"
                                      multiple
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item
                                          ripple
                                          @click="fn_selectall"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              :color="
                                                documenttype.length > 0
                                                  ? 'indigo darken-4'
                                                  : ''
                                              "
                                            >
                                              {{ icon }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <span v-if="index < maxDisplay">{{ $t('default') === 'th' ? item.document_type_th : item.document_type_eng }} </span>
                                        <span
                                          v-if="index === maxDisplay"
                                          class="grey--text caption"
                                          >(+{{
                                            documenttype.length - maxDisplay
                                          }}
                                          ประเภท)</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <!-- chanel -->
                                <!-- <v-layout row wrap >
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="chanel"
                                    :items="listDoctype"
                                    item-value="document_type"
                                    item-text="document_type"
                                    :label="$t('myoutboxPage.conditionOptional.chanel')"
                                    prepend-icon="reorder"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  ></v-select>
                                </v-flex>
                              </v-layout> -->

                                <v-layout row wrap justify-center>
                                  <v-flex xs6 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      @click:clear="clearDocumentId"
                                      class="pa-1 pt-0 pb-0"
                                      v-model="documentid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.filedocumentid'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs6 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      :disabled="!!!documentid"
                                      class="pa-1 pt-0 pb-0"
                                      v-model="documentidto"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.todateupload'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs2 lg3 mt-2 d-flex>
                                    <div>
                                      {{ $t("toolbar.searching.startdate") }}
                                    </div>
                                  </v-flex>
                                  <!-- ช่องกรอก เอกสารลงวันที่ -->
                                  <v-flex xs10 lg3>
                                    <v-dialog
                                      ref="dialogFromdatedocument"
                                      v-model="modalFromdatedocument"
                                      :return-value.sync="fromdatedocument"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          :color="color.theme"
                                          :label="
                                            $t(
                                              'myoutboxPage.conditionOptional.fromdatedocument'
                                            )
                                          "
                                          v-model="fromdatedocument"
                                          @click:prepend="
                                            modalFromdatedocument = true
                                          "
                                          append-icon="event"
                                          v-on="on"
                                          readonly
                                          outlined
                                          dense
                                          clearable
                                          :persistent-hint="fromdate_hint"
                                          @click:clear="todatedocument = ''"
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                        ></v-text-field>
                                        <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                        <!-- <v-text-field
                                        :color="color.theme"
                                        :label="$t('myoutboxPage.conditionOptional.fromdatedocument')"
                                        v-model="fromdatedocument"
                                        @click:prepend="modalFromdatedocument = true, fromdatedocument = datenow_eng,on"
                                        :placeholder="$t('myoutboxPage.yyyy_mm_dd')"
                                        prepend-icon="event"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="fromdate_hint"
                                        @click:clear="todatedocument = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                      </template>
                                      <v-date-picker
                                        v-model="fromdatedocument"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="changeFromdatedocument()"
                                        :color="color.theme"
                                        :min="setdate_(todatedocument)"
                                        :max="todatedocument"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click.stop="
                                            modalFromdatedocument = false
                                          "
                                          >{{
                                            $t(
                                              "myoutboxPage.conditionOptional.closefromdatedocument"
                                            )
                                          }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>

                                  <!-- <v-flex mt-2 d-flex justify-center>-</v-flex> -->
                                  <v-flex xs2 lg3 mt-2 d-flex>
                                    <div>
                                      {{ $t("toolbar.searching.startdate") }}
                                    </div>
                                  </v-flex>
                                  <!-- ช่องกรอก เอกสารลงวันที่ -->
                                  <v-flex xs10 lg3>
                                    <v-dialog
                                      ref="dialogTodatedocument"
                                      v-model="modalTodatedocument"
                                      :return-value.sync="todatedocument"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          readonly
                                          :color="color.theme"
                                          :label="
                                            $t(
                                              'myoutboxPage.conditionOptional.todatedocument'
                                            )
                                          "
                                          v-model="todatedocument"
                                          append-icon="event"
                                          @click:prepend="
                                            modalTodatedocument = true
                                          "
                                          v-on="on"
                                          outlined
                                          dense
                                          clearable
                                          :persistent-hint="todate_hint"
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                        ></v-text-field>
                                        <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                        <!-- <v-text-field
                                        :color="color.theme"
                                        :label="$t('myoutboxPage.conditionOptional.todatedocument')"
                                        v-model="todatedocument"
                                        prepend-icon="event"
                                        @click:prepend="modalTodatedocument = true, todatedocument = datenow_eng,on"
                                        :placeholder="$t('myoutboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="todate_hint"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                      </template>
                                      <v-date-picker
                                        v-model="todatedocument"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="
                                          $refs.dialogTodatedocument.save(
                                            todatedocument
                                          )
                                        "
                                        :color="color.theme"
                                        :min="fromdatedocument"
                                        :max="setdate(fromdatedocument)"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click.stop="
                                            modalTodatedocument = false
                                          "
                                          >{{
                                            $t(
                                              "myoutboxPage.conditionOptional.closetodatedocument"
                                            )
                                          }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                </v-layout>

                                <!-- ช่องกรอก SellerTaxID -->
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="sellertaxid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.sellertaxid'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="sellerbranchid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.sellerbranchid'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                                <!-- ช่องกรอก BuyerTaxID -->
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="buyertaxid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.buyertaxid'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="buyerbranchid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.buyerbranchid'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                                <!-- Po_number -->
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md12 lg12>
                                    <v-text-field
                                     clearable
                                     outlined
                                     dense
                                     class="pa-1 pt-0 pb-0"
                                     v-model="ponumber"
                                     :label="$t('myoutboxPage.conditionOptional.ponumber')"
                                     :color="color.theme"
                                    ></v-text-field>
                                  </v-flex>
                                 </v-layout>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-flex xs12 lg12 class="text-center pb-5">
                        <br />
                        <!-- เคลียร์ -->
                        <v-btn
                          class="ma-1 white--text"
                          :color="color.theme"
                          :disabled="loaddataprogress"
                          :loading="loaddataprogress"
                          @click="clear_data()"
                        >
                          <v-icon left>mdi-refresh</v-icon>
                          {{ $t("myinboxPage.clear_data") }}
                        </v-btn>
                        <!-- ค้นหา -->
                        <v-btn
                          class="ma-1 white--text"
                          :color="color.theme"
                          :disabled="loaddataprogress"
                          :loading="loaddataprogress"
                          @click="searchdocument()"
                        >
                          <v-icon left>search</v-icon>
                          &nbsp;
                          {{ $t("myinboxPage.search") }}
                        </v-btn>
                        <!-- ลบจาก Filter -->
                        <v-btn
                          class="ma-1 white--text"
                          color="#C51D1D"
                          :disabled="loaddataprogress"
                          :loading="loaddataprogress"
                          @click="delete_filter()"
                        >
                          <v-icon left>mdi-trash-can-outline</v-icon>
                          {{ $t("myoutboxPage.delete_filter") }}
                        </v-btn>
                        <!-- กู้คืน -->
                        <v-btn
                          class="ma-1 white--text"
                          color="#479F31"
                          :disabled="loaddataprogress"
                          :loading="loaddataprogress"
                          @click="restore_filter()"
                        >
                          <v-icon left>mdi-history</v-icon>
                          {{ $t("myoutboxPage.restore_filter") }}
                        </v-btn>
                      </v-flex>
                      <br />
                  <v-flex xs5 sm3>
                    <v-select
                      prepend-inner-icon="sort"
                      hide-details
                      outlined
                      solo
                      dense
                      v-model="size"
                      :items="listsize"
                      class="text-center ma-1 ml-3"
                    >
                      <template v-slot:item="{ item }">
                        <div class="text-center">
                          <span style="font-size: 17px;">{{ item }}</span>
                        </div>
                      </template>
                      <template v-slot:selection="{ item }">
                        <div class="text-center">
                          <span style="font-size: 17px;">{{ item }}</span>
                        </div>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-flex xs7 sm9 class="text-right">
                    <v-chip
                      class="ma-2"
                      color="#1876D1" 
                      :text-color="color.chipText"
                    >
                      <v-icon left>folder</v-icon>
                      <span>{{countfolders}} <span v-if="resolutionScreen >= 400">{{countfolder > 1 ? $t('folders'): $t('folder')}}</span></span>
                      <!-- {{countfolders}} {{countfolder > 1 ? $t('folders'): $t('folder')}}                     -->
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      color="#5AB685" 
                      :text-color="color.chipText"
                    >
                      <v-icon left>mdi-file</v-icon>
                      <span>{{countfiles}} <span v-if="resolutionScreen >= 400">{{countfile > 1 ? $t('files'): $t('file')}}</span></span>
                      <!-- {{countfiles}} {{countfile > 1 ? $t('files'): $t('file')}}                      -->
                    </v-chip>
                  </v-flex>
                </v-layout>
                <br />
            
                <div v-if="resolutionScreen >= 500 ">
                  <v-flex xs12 lg12 class="ma-2">
                    <v-pagination
                    :total-visible="10"
                    v-model="page"
                    :length="pageCount || 0"
                    :color="color.theme"
                    @input="clickpagination()"
                    ></v-pagination>
                  </v-flex>
                  <v-data-table
                    v-model="filemultipledelete"
                    :headers="headers"
                    :items="rootfile"
                    :no-data-text="$t('tablefile.empty')"
                    :single-select="singleSelect"
                    :search="search"
                    item-key="file_id"
                    item-selected
                    :show-select="statusmutipledelete"
                    class="elevation-1"
                    :page.sync="page"
                    :items-per-page="size"
                    :hide-default-footer="true"
                    @page-count="pageCount = $event"
                  >
                    <!-- <template v-slot:top>
                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                    </template>-->
                    <template v-slot:[`header.file_type`]="{ header }">
                      <span :style="headerTable">{{ header.text }}</span>
                    </template>
                    <template v-slot:[`header.file_name`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                          <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span class="pointer"  @click="multiSort=!multiSort , sortby='name', clickSort=true ,sortFile(rootfile) "
                      :style="headerTable">{{ $t(header.text) }}</span> -->
                    </template>
                    <template v-slot:[`header.file_document_no`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                          <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span class="pointer"  @click="multiSort=!multiSort ,sortby='owner', clickSort=true ,sortFile(rootfile) "
                      :style="headerTable">{{ $t(header.text) }}</span> -->
                    </template>
                    <template v-slot:[`header.PO`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                          <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span class="pointer"  @click="multiSort=!multiSort ,sortby='owner', clickSort=true ,sortFile(rootfile) "
                      :style="headerTable">{{ $t(header.text) }}</span> -->
                    </template>
                    <template v-slot:[`header.file_document_date`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                          <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span class="pointer"  @click="multiSort=!multiSort ,sortby='date', clickSort=true ,sortFile(rootfile)  " 
                      :style="headerTable">{{ $t(header.text) }}</span> -->
                    </template>
                    <template v-slot:[`header.file_trash_dtm`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                          <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span class="pointer"  @click="multiSort=!multiSort ,sortby='date', clickSort=true ,sortFile(rootfile)  " 
                      :style="headerTable">{{ $t(header.text) }}</span> -->
                    </template>
                    <template v-slot:[`header.file_size`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                          <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span class="pointer"  @click="multiSort=!multiSort ,sortby='size', clickSort=true ,sortFile(rootfile) "
                      :style="headerTable">{{ $t(header.text) }}</span> -->
                    </template>
                    <template v-slot:[`header.file_document_type`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                          <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span class="pointer"  @click="multiSort=!multiSort ,sortby='size', clickSort=true ,sortFile(rootfile) "
                      :style="headerTable">{{ $t(header.text) }}</span> -->
                    </template>
                    <template v-slot:[`header.file_status`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                    </template>

                    <template v-slot:item="props">
                      <!-- @dblclick="gotodirectory(props.item.file_id,props.item.file_type)" -->
                      <tr 
                        @contextmenu="rightclickfileandfolder($event,props.item)" 
                        :style="selectItemTable(props.item.file_id, 'table') + 'cursor: pointer;'"
                        @click="setSelectitemtable(props.item)"
                      >
                        <!-- <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="props.item" />
                        </td>-->
                        <td class="text-center" v-show="statusmutipledelete">
                            <v-checkbox
                              v-model="filemultipledelete"
                              :value="props.item"
                            />
                          </td>
                        <td width="7%" class="text-center">
                          <v-icon
                            large
                            :color="props.item.file_icon[1]"
                          >{{ props.item.file_icon[0] }}</v-icon>
                        </td>
                        <td width="33%" :title="props.item.file_name">{{ props.item.file_name | subStr}}</td>
                        <td width="15%">{{ props.item.file_document_no}}</td>
                        <td class="text-center" width="15%">{{ props.item.po_number === '' || props.item.po_number === null ? "-" : props.item.po_number}}</td>                    
                        <td width="18%">{{ formatdatetime_doc(props.item.file_document_date)}}</td>
                        <td width="18%">{{ formatdatetime(props.item.file_trash_dtm)}}</td>
                        <td width="15%">{{ calculatesize(props.item.file_size)}}</td>
                        <td width="15%">{{ props.item.file_document_type}}</td>
                        <td width="10%" class="text-center" v-if="props.item.file_status === 'M'"></td>
                        <td width="12%" v-else class="text-center">
                          <v-menu>
                          <template v-slot:activator="{ on }">
                              <v-btn class="elevation-0" fab small v-on="on" outlined @click="setSelectitemtable(props.item)">
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                          </template>
                          <v-list>
                            <v-list-item  @click="recovery(props.item)">
                                <v-list-item-icon>
                                  <v-icon>
                                    history
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{$t('trashRClick.restore')}}
                                  </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="dialogtrashinboxoutbox(), newdatafile = props.item">
                                <v-list-item-icon>
                                  <v-icon>
                                   delete
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                  {{$t('trashRClick.forcedelete')}}
                                  </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
                <div v-else class="ma-2">
                  <v-list two-line class="mb-6 pb-6 elevation-1" v-if="rootfile.length !== 0">
                    <v-list-item
                      v-for="item in rootfile"
                      :key="item.title"
                      style="cursor: pointer;"
                    >
                      <!-- @dblclick="gotodirectory(item.file_id,item.file_type)" -->
                      <v-list-item-avatar>
                        <div>
                          <v-icon
                            x-large
                            :color="item.file_icon[1]"
                            style="font-weight: 100"
                          >{{ item.file_icon[0] }}</v-icon>
                        </div>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title v-text="item.file_name"></v-list-item-title>
                        <v-list-item-subtitle v-text="calculatesize(item.file_size)"></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-menu>
                          <template v-slot:activator="{ on }">
                              <v-btn class="elevation-0" fab small v-on="on" outlined>
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                          </template>
                          <v-list>
                            <v-list-item  @click="recovery(item)">
                                <v-list-item-icon>
                                  <v-icon>
                                    history
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{$t('trashRClick.restore')}}
                                  </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="dialogtrashinboxoutbox(), newdatafile = item">
                                <v-list-item-icon>
                                  <v-icon>
                                   delete
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                  {{$t('trashRClick.forcedelete')}}
                                  </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <!-- 
                    <v-divider inset></v-divider>-->
                  </v-list>
                  <v-list v-else class=" elevation-1">
                     <v-list-item>
                          <!-- หน้าจอธรรมดา -->
                          <v-list-item-content v-if="resolutionScreen >= 500">
                            <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                          </v-list-item-content>
                          <!-- หน้าจอมือถือ -->
                          <v-list-item-content v-else>
                        <v-card-text class="mt-4 mb-4">
                          <v-img
                            :src="require('../assets/img/icon_empty_data.png')"
                            max-width="132"
                            max-height="150"
                            class="mx-auto"
                          />
                          <v-list-item-title
                            class="text-center mt-8"
                            v-text="$t('myoutboxPage.Nodata')"
                            :style="
                              'font-size: 16px; font-weight: 700; line-height: 24px; color:' +
                                color.theme +
                                ';'
                            "
                          ></v-list-item-title>
                        </v-card-text>
                      </v-list-item-content>
                        </v-list-item>
                      </v-list>
                </div>
                <br />
                <v-layout row wrap justify-center>
                  <v-flex xs11 lg11>
                    <v-pagination
                    :total-visible="10"
                    v-model="page"
                    :length="pageCount || 0"
                    :color="color.theme"
                    @input="clickpagination()"
                    ></v-pagination>
                  </v-flex>
                </v-layout>

                <!-- <infinite-loading @infinite="loadfile"></infinite-loading> -->
              </v-flex>
            </v-layout>
            </v-container>

          <!-- <listfile @closemaincontextmenu="showMenu = false"></listfile> -->
          <!-- ยังไม่สามารถลบถาวรได้และกู้คืนได้ -->
          <!-- <trashrightclick
            :show="showcurrentMenu"
            :AreaX="x"
            :AreaY="y"
            @callremove="msgalert"
            @callupdatestatusfile="setPermissionFile"
            @closecurrent="showcurrentMenu = false,loadfolder(), loadstorage()"
            :file="currentfile"
          ></trashrightclick> -->
          <trashforever
            :show="opendialog"
            @closedialog ="opendialog = false, statusmutipledelete = false ,clearmultipledelete()"
            @multidelete ="opendialog = false, multipledelete() ,statusmutipledelete = false"
            :file ="filemultipledelete"
          >
          </trashforever>
          <dialogmultirestore
          :show="opendialogmultirestore"
          @closedialog="opendialogmultirestore=false , (filemultipledelete = [])"
          @closedialogreload="(opendialogmultirestore = false),(filemultipledelete = []),loadfiles()"
          :file ="filemultipledelete"
          ></dialogmultirestore>
          <trashinboxoutbox  
          :show= "opendialogdialog"  
          @closedialog="opendialogdialog = false"  
          :filedata ="newdatafile" 
          @loadfiles="loadfiles()"
          :type="type_outbox"
          />
          <dialogmultideleteforever
            :show="opendialogmultideleteforever"
            :filedata ="filemultipledelete"
            @closedialog ="opendialogmultideleteforever = false, statusmutipledelete = false ,filemultipledelete=[],clearmultipledelete()"
            @closedialogreload="(opendialogmultideleteforever = false),(filemultipledelete = []),loadfiles()"
          ></dialogmultideleteforever>
          <dialogcomfirmemptytrashalloutbox
            :show="opendialogcomfirmemptytrashalloutbox"
            @close="opendialogcomfirmemptytrashalloutbox = false"
            @confirmemptytrash="fn_emptytrash()"
          ></dialogcomfirmemptytrashalloutbox>
          <dialogcheckdeletefilter
            :filedata="list_documents"
            :totalfile="total_delete_file"
            :type="type_outbox"
            :show="opendialogcheckdeletefilter"
            @closedialog="opendialogcheckdeletefilter = false"
            @reload="loadfiles()"
          ></dialogcheckdeletefilter>
          <dialogalertcheckdeletefilter
            :show="opendialogalertcheckdeletefilter"
            @closedialog="opendialogalertcheckdeletefilter = false"
          ></dialogalertcheckdeletefilter>
          <dialogcheckrestorefilter
            :filedata="list_documents"
            :totalfile="total_delete_file"
            :type="type_outbox"
            :show="opendialogcheckrestorefilter"
            @closedialog="opendialogcheckrestorefilter = false"
            @reload="loadfiles()"
          ></dialogcheckrestorefilter>
          <dialogalertcheckrestorefilter
            :show="opendialogalertcheckrestorefilter"
            @closedialog="opendialogalertcheckrestorefilter = false"
          ></dialogalertcheckrestorefilter>
          <dialogalersearchdonotfillout
              :show="opendialogalersearchdonotfillout"
              @closedialog="opendialogalersearchdonotfillout = false"
              ></dialogalersearchdonotfillout>
          </v-card-text>
        </v-card>
      </v-card>
    </v-content>
    <v-dialog v-model="msgConfirm" persistent max-width="500">
      <v-card>
        <v-card-title>
          <b>{{ $t('trashPage.messageboxheader')}}</b>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text><v-icon color="red">folder</v-icon>{{ $t('trashPage.messageboxsubheader')}}</v-card-text>
        <br />
        <v-card-text>
          <v-icon color="amber">error_outline</v-icon><b>{{ $t('trashPage.messageboxsubheader2')}}</b><v-icon color="amber">error_outline</v-icon>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="msgConfirm = false"
          >{{ $t('trashPage.canceldelete')}}</v-btn>
          <v-btn
            color="red  darken-1 white--text"
            depressed
            @click="callremove(datadelete)"
            :loading="processdelete"
          >{{ $t('trashPage.forcedelete')}}</v-btn>
        </v-card-actions>
        <v-progress-linear :active="processdelete" indeterminate color="green"></v-progress-linear>
      </v-card>
    </v-dialog>
    <div style="visibility:hidden;" id="dropzone">
      <div id="textnode">
        <v-icon size="200" dark>cloud_upload</v-icon>
        <h3>วางไฟล์เพื่อทำการอัปโหลด</h3>
      </div>
    </div>
    </div>
  </v-app>
</template>

<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
//import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";
import format from "date-fns/format";
// Import Component
const trashinboxoutbox = () => import("../components/optional/dialog-trashinboxoutbox");
const trashforever =() => import("../components/optional/dialog-trashforever");
const uploadfolder = () => import("../components/upload/dialog-uploadfolder");
const uploadfiles = () => import("../components/upload/dialog-uploadfiles");
const currentfilerightclick = () =>
  import("../components/contextmenu/currentfilerightclick");
const trashrightclick = () =>
  import("../components/contextmenu/trashrightclick");
const dialogmultirestore = () => import("../components/optional/dialog-multirestore");
const dialogmultideleteforever = () => import("../components/optional/dialog-multideleteforeverinboxoutbox");
const dialogcomfirmemptytrashalloutbox = () => import("../components/optional/dialog-confirmEmptyTrashAll-outbox.vue")
// const onebox_toolbar = () => import("../components/layout/layout-toolbar");
const dialogcheckdeletefilter = () =>
  import("../components/optional/dialog-checkdelete-filter.vue");
const dialogalertcheckdeletefilter = () =>
  import("../components/optional/dialog-alert-checkdelete-filter.vue")
const dialogcheckrestorefilter = () =>
  import("../components/optional/dialog-checkrestore-filter.vue")
const dialogalertcheckrestorefilter = () => 
  import("../components/optional/dialog-alert-checkrestore-filter.vue")
const dialogalersearchdonotfillout = () =>
  import("../components/optional/dialog-search-donotfillout.vue");
import onebox_toolbar from "../components/layout/layout-toolbar-new";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  components: {
    onebox_toolbar,
    VueFullScreenFileDrop,
    trashrightclick,
    uploadfiles,
    uploadfolder,
    trashforever,
    trashinboxoutbox,
    dialogmultirestore,
    dialogmultideleteforever,
    dialogcomfirmemptytrashalloutbox,
    dialogcheckdeletefilter,
    dialogalertcheckdeletefilter,
    dialogcheckrestorefilter,
    dialogalertcheckrestorefilter,
    dialogalersearchdonotfillout,
  },
  data: function() {
    return {
      arrayfileidselect: [],
      sorting: {
        sort: [
          {
            id: "document_date",
            text: "sorting.document_date",
          },
          {
            id: "document_type",
            text: "sorting.document_type",
          },
          {
            id: "cre_dtm",
            text: "sorting.cre_dtm",
          },
          {
            id: "customer_name",
            text: "sorting.customer_name",
          },
          {
            id: "saler_name",
            text: "sorting.saler_name",
          },
          {
            id: "filename",
            text: "sorting.filename",
          },
          {
            id: "document_no",
            text: "sorting.doc_no",
          },
          {
            id: "status",
            text: "sorting.status",
          },
        ],
        direction: [
          {
            id: "ASC",
            text: "sorting.ascending",
          },
          {
            id: "DESC",
            text: "sorting.descending",
          },
        ],
      },
      sort_by: "filename",
      direction: "ASC",
      datenow: format(new Date(), "dd_MM_yyyy"),
      datenow_eng: format(new Date(), "yyyy-MM-dd"),
      opendialogalersearchdonotfillout: false,
      list_documents:[],
      type_outbox:"",
      total_delete_file:0,
      opendialogcheckdeletefilter:false,
      opendialogalertcheckdeletefilter: false,
      opendialogcheckrestorefilter:false,
      opendialogalertcheckrestorefilter: false,
      show_info_doc: false,
      show_info:false,
      fromdate: "",
      fromdate_hint: "",
      todate_hint: "",
      filename:"",
      fileextension: [],
      listExtensionFile: [
        {data_type:"pdf"},
        {data_type:"xml"},
        {data_type:"xlsx"},
        {data_type:"xls"}
      ],
      maxDisplayfileextension: 1,
      maxDisplay: 1,
      documentid: "",
      documentidto: "",
      documenttype: [],
      listDoctype:[
        {
          document_type_code: "80",
          document_type_eng: "Debit Note (80)",
          document_type_th:"ใบเพิ่มหนี้ (80)"
        },
        {
          document_type_code: "81",
          document_type_eng: "Credit Note (81)",
          document_type_th:"ใบลดหนี้ (81)"
        },
        {
          document_type_code: "207",
          document_type_eng: "Delivery Note (207)",
          document_type_th:"ใบส่งของ (207)"
        },
        {
          document_type_code: "325",
          document_type_eng: "Performa Invoice (325)",
          document_type_th:"บัญชีสินค้าเดินพิธี (325)"
        },
        {
          document_type_code: "380",
          document_type_eng: "Invoice (380)",
          document_type_th:"ใบแจ้งหนี้ (380)"
        },
        {
          document_type_code: "381",
          document_type_eng: "Credit Note (381)",
          document_type_th:"ใบลดหนี้การค้า (381)"
        },
        {
          document_type_code: "383",
          document_type_eng: "Dabit Note (383)",
          document_type_th:"ใบเพิ่มหนี้การค้า (383)"
        },
        {
          document_type_code: "388",
          document_type_eng: "Tax Invoice (388)",
          document_type_th:"ใบกำกับภาษี (388)"
        },
        {
          document_type_code: "623",
          document_type_eng: "Forwarder's Invoice (623)",
          document_type_th:"ใบเสร็จค่า Freight (623)"
        },
        {
          document_type_code: "T01",
          document_type_eng: "Bill (T01)",
          document_type_th:"ใบรับ (ใบเสร็จรับเงิน) (T01)"
        },
        {
          document_type_code: "T02",
          document_type_eng: "Invoice/Tax Invoice (T02)",
          document_type_th:"ใบแจ้งหนี้/ใบกำกับภาษี (T02)"
        },
        {
          document_type_code: "T03",
          document_type_eng: "Bill/Tax Invoice (T03)",
          document_type_th:"ใบเสร็จรับเงิน/ใบกำกับภาษี (T03)"
        },
        {
          document_type_code: "T04",
          document_type_eng: "Delivery Note/Tax Invoice (T04)",
          document_type_th:"ใบส่งของ/ใบกำกับภาษี (T04)"
        },
        {
          document_type_code: "T05,T06",
          document_type_eng: "ABB (T05,T06)",
          document_type_th:"ใบกำกับภาษีอย่างย่อ (T05,T06)"
        },
        {
          document_type_code: "T07",
          document_type_eng: "Cancellation Note (T07)",
          document_type_th:"ใบยกเลิก (T07)"
        }
      ],
      fromdateupload: "",
      modalFromdateupload: false,
      todateupload: "",
      modalTodateupload: false,
      fromdatedocument: "",
      modalFromdatedocument: false,
      todatedocument: "",
      modalTodatedocument: false,
      sellertaxid: "",
      sellerbranchid: "",
      sendername: "",
      receivername: "",
      buyertaxid: "",
      buyerbranchid: "",
      ponumber:"",
      panel: [0],
      total_list_inbox_selected:0,
      opendialogcomfirmemptytrashalloutbox:false,
      textdownload:"",
      textsearchoutbox:"",
      list_outbox_selected:[],
      show_delete_total:false,
      show_menu: false,
      show_delete: false,
      show_restore: false,
      show_clear: false,
      opendialogmultideleteforever:false,
      opendialogmultirestore:false,
      newdatafile :[],
      opendialogdialog : false,
      opendialog : false,
      filemultipledelete: [],
      countfolder:'',
      countfile:'',
      file:[],
      folder:[],
      search:'',
      statusmutipledelete: false,
      page: 1,
      size: 50,
      listsize: [20, 50, 100],
      processdelete: false,
      datadelete: {},
      msgConfirm: false,
      lists: [],
      pagination: {
        rowsPerPage: 100000,
        sortBy: "file_id",
        totalItems: 0,
        rowsPerPageItems: [1, 2, 4, 8]
      },
      loaddataprogress: true,
      parentfolder: "",
      loadingfile: false,
      openUploadfolder: false,
      openUploadfiles: false,
      showcurrentMenu: false,
      showMenu: false,
      currentfile: {     
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: ""
      },
      x: 0,
      y: 0,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "#",
          align: "center",
          value: "file_type",
          width: "7%",
          sortable: false
        },
        {
          text: "tablefile.filenameonly",
          align: "left",
          value: "file_name",
          width: "33%",
          sortable: false
        },
        {
          text: "tablefile.docno",
          align: "left",
          value: "file_document_no",
          width: "15%",
          sortable: false
        },
        {
          text: "tablefile.po_number",
          align: "center",
          value: "PO",
          width: "10%",
          sortable: false
        },
        {
          text: "tablefile.docdate",
          align: "left",
          value: "file_document_date",
          width: "15%",
          sortable: false
        },
        {
          text: "tablefile.trashdtm",
          value: "file_trash_dtm",
          width: "15%",
          align: "left",
          sortable: false
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "15%",
          align: "left",
          sortable: false
        },
        {
          text: "tablefile.doctype",
          value: "file_document_type",
          width: "15%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "12%",
          align: "center",
          sortable: false
        }
      ],
      sortby: '',
      clickSort:false,
      multiSort:false,
      rootfile: [],
      folderSort:[],
      fileSort: [],
      sortdata : [],
      buff:[],
      statusetax: [],
      liststatusetax: [
        {
          statusetax_type: "ขอคืนภาษีแล้ว",
          statusetax_value: "Y",
        },
        {
          statusetax_type: "ยังไม่ขอคืนภาษี",
          statusetax_value: "N",
        },
      ],
      statusonechat: [],
      liststatusonechat: [
        {
          status_text: "ไม่ส่ง",
          status_code: "0",
        },
        {
          status_text: "รอส่ง",
          status_code: "1",
        },
        {
          status_text: "ส่งแล้ว",
          status_code: "2",
        },
        {
          status_text: "อ่านแล้ว",
          status_code: "3",
        },
      ],
      oneboxtype: [],
      boxtype: [
        {
          box_type: "มี",
          box_value: "Y",
        },
        {
          box_type: "ไม่มี",
          box_value: "N",
        },
      ],
    };
  },
  filters: {
  	subStr: function(string) {
      if(string == null || string == '' || string == undefined){
        return string
      }else{
        if (string.length > 30) return string.substring(0, 30) + "...";
      else return string;
      }
    }
  },
  computed: {
    ...mapState(["username", "authorize", "account_active","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataAccountId"
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.countfile;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      }
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      console.log("start",start);
      console.log("end",end);
      console.log(this.rootfile.slice(start, start + this.size));
      return this.rootfile.slice(start, start + this.size);
    },
    countfolders(){
      let countfolder = this.folder.length;
      return countfolder;
    },
    countfiles(){
      let countfile = this.countfile;
      return countfile;
    },headerTable(){
      return 'color:'+this.color.theme+';'+'font-weight: 600; font-size:15px;'
    },headerPage(){
      return 'color:'+this.color.theme+';'+'font-size: 18px; font-weight: 600;'
    },colorVapp(){
      return 'background:'+this.color.BG+';'
    },colorSort(){
      return 'color:'+this.color.alertText+';'
    },colorProgress(){
      return 'color:'+this.color.theme+';'
    },
    icon() {
      if (this.Alldoctype) return "mdi-close-box";
      if (this.Somedoctype) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    icons() {
      if (this.Allfileextension) return "mdi-close-box";
      if (this.Somefileextension) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    icon_() {
      if (this.Allstatusetax) return "mdi-close-box";
      if (this.Somestatusetax) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    Alldoctype() {
      return this.documenttype.length === this.listDoctype.length;
    },
    Somedoctype() {
      return this.documenttype.length > 0 && !this.Alldoctype;
    },
    Allfileextension() {
      console.log("this.fileextension.length", this.fileextension.length);
      console.log(
        "this.listExtensionFile.length",
        this.listExtensionFile.length
      );
      return this.fileextension.length === this.listExtensionFile.length;
    },
    Somefileextension() {
      return this.fileextension.length > 0 && !this.Allfileextension;
    },
    Allstatusetax() {
      console.log(this.statusetax.length === this.liststatusetax.length);
      return this.statusetax.length === this.liststatusetax.length;
    },
    Somestatusetax() {
      return this.statusetax.length > 0 && !this.Allstatusetax;
    },
    icon_() {
      if (this.Alloneboxtype) return "mdi-close-box";
      if (this.Someoneboxtype) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    icon_all() {
      if (this.Allstatussendtoonechat) return "mdi-close-box";
      if (this.Somestatussendtoonechat) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    Alldoctype() {
      return this.documenttype.length === this.listDoctype.length;
    },
    Somedoctype() {
      return this.documenttype.length > 0 && !this.Alldoctype;
    },
    Allfileextension() {
      return this.fileextension.length === this.listExtensionFile.length;
    },
    Somefileextension() {
      return this.fileextension.length > 0 && !this.Allfileextension;
    },
    Alloneboxtype() {
      return this.oneboxtype.length === this.boxtype.length;
    },
    Someoneboxtype() {
      return this.oneboxtype.length > 0 && !this.Alloneboxtype;
    },
    Allstatussendtoonechat() {
      return this.statusonechat.length === this.liststatusonechat.length;
    },
    Somestatussendtoonechat() {
      return this.statusonechat.length > 0 && !this.Allstatussendtoonechat;
    }
  },
  methods: {
    clearDocumentId () {
      this.documentidto = ""
    },
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      // this.todateupload = format(new Date(), "yyyy-MM-dd");
      this.todateupload = this.formatDatetodoc2(this.fromdateupload)
    },
    changeFromdatedocument() {
      this.$refs.dialogFromdatedocument.save(this.fromdatedocument);     
      // this.todatedocument = format(new Date(), "yyyy-MM-dd");
      this.todatedocument = this.formatDatetodoc2(this.fromdatedocument)
    },
      fn_selectall_fileextension() {
      let listex = [];
      this.$nextTick(() => {
        if (this.Allfileextension) {
          this.fileextension = [];
          console.log("fileextension", this.fileextension);
        } else {
          console.log(this.fileextension);
          console.log(this.listExtensionFile);
          for (let i = 0; i < this.listExtensionFile.slice().length; i++) {
            listex.push(this.listExtensionFile[i].data_type);
          }
          this.fileextension = listex;
          // // this.fileextension = this.listExtensionFile.slice()
          // console.log("fileextension else",this.fileextension);
        }
      });
    },
    fn_selectallstatusonechat() {
      let liststatus = [];
      this.$nextTick(() => {
        if (this.Allstatussendtoonechat) {
          this.statusonechat = [];
        } else {
          for (let i = 0; i < this.liststatusonechat.slice().length; i++) {
            liststatus.push(this.liststatusonechat[i].status_code);
          }
          this.statusonechat = liststatus;
        }
      });
    },
    fn_selectall_boxtype() {
      this.$nextTick(() => {
        if (this.Alloneboxtype) {
          this.oneboxtype = [];
          console.log("oneboxtype", this.oneboxtype);
        } else {
          this.oneboxtype = this.boxtype.slice();
          console.log("this.oneboxtype", this.oneboxtype);
        }
      });
    },
    fn_selectall() {
      let listdocument = [];
      this.$nextTick(() => {
        console.log(this.Alldoctype);
        if (this.Alldoctype) {
          this.documenttype = [];
          console.log("documenttype", this.documenttype);
        } else {
          for (let i = 0; i < this.listDoctype.slice().length; i++) {
            listdocument.push(this.listDoctype[i].document_type);
          }
          this.documenttype = listdocument;
          console.log("documenttype else", this.documenttype);
        }
      });
    },
    fn_selectall_statusetax() {
      this.$nextTick(() => {
        if (this.Allstatusetax) {
          this.statusetax = [];
          console.log("statusetax", this.statusetax);
        } else {
          this.statusetax = this.liststatusetax.slice();
          console.log("this.statusetax", this.statusetax);
        }
      });
    },
    setdate_(date_) {
      if (date_) {
        console.log("date_", date_);
        var date = new Date(date_);
        // var y = date.getFullYear()
        // var m = date.getMonth() + 1
        // var d = date.getDay() + 90
        date.setDate(date.getDate() - 90);
        console.log(date);
        return new Date(date).toISOString().substr(0, 10);
      }
      return date_;
    },
    setdate(date_) {
      if (date_) {
        console.log("date_", date_);
        var date = new Date(date_);
        // var y = date.getFullYear()
        // var m = date.getMonth() + 1
        // var d = date.getDay() + 90
        date.setDate(date.getDate() + 90);
        console.log(date);
        return new Date(date).toISOString().substr(0, 10);
      }
      return date_;
    },
    checkComfirmEmptyTrashAll() {
      if (this.rootfile.length > 0) {
        this.opendialogcomfirmemptytrashalloutbox = true
      } else {
        Toast.fire({
          icon: "error",
          title: this.$t("trashPage.nofileandfolderintrash"),
        });
      }
    },
    clickpagination() {
      this.loadfiles();
    },
    async fn_emptytrashinbox_selected(){
      if(this.filemultipledelete.length == 0){
        Toast.fire({
            icon: "warning",
            title: this.$t("myinboxPage.msg.nofile")
        });
      }else{
      this.textdownload = "กำลังลบไฟล์ที่เลือก";
      // this.processloader = true;
      this.loaddataprogress = true;
      console.log("filemultipledelete",this.filemultipledelete);
      this.list_outbox_selected = [];
      for(let i=0; i < this.filemultipledelete.length; i++ ){
        this.list_outbox_selected.push(this.filemultipledelete[i].inbox_id)
      }
      this.total_list_inbox_selected = this.list_outbox_selected.length
      console.log("console.log(this.filemultipledelete)",this.filemultipledelete);
      let payload;
        payload = {
          business_id : this.dataAccountActive.business_info.business_id,
          data_type : "outbox",
          inbox_id: this.list_outbox_selected
        };
        console.log("payload",payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/v1/delete_file_inbox_outbox",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            console.log("res", response);
            if (response.data.status === "OK") {
              Toast.fire({
                icon: "success",
                title: "ลบสำเร็จ จำนวน " + " " +  this.total_list_inbox_selected + " " + "เอกสาร",
              });
              this.loadstorage();
              this.loadfiles();
              this.loaddataprogress = false;
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
              this.loaddataprogress = false;
            }
          });
        }
    },
    async fn_emptytrash(){
      this.textdownload = "กำลังลบไฟล์ทั้งหมด";
      this.loaddataprogress = true;
      // this.processloader = true;
        let payload;
        payload = {
          business_id : this.dataAccountActive.business_info.business_id,
          data_type : "outbox",
          inbox_id: ["all"]
        };
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/v1/delete_file_inbox_outbox",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            console.log("res", response);
            if (response.data.status === "OK") {
              this.opendialogcomfirmemptytrashalloutbox = false;
              // this.processloader = false;
              this.loaddataprogress = false;
              Toast.fire({
                icon: "success",
                title: "ลบเอกสารทั้งหมดสำเร็จ",
              });
              this.loadstorage();
              this.loadfiles();
            } else {
              // this.processloader = false;
              this.loaddataprogress = false;
              this.opendialogcomfirmemptytrashalloutbox = false;
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
    },
    dialogtrashinboxoutbox(){
      this.opendialogdialog = true
    },
   async recovery(filerecover){
      let payload
      payload = {
          business_id : this.dataAccountActive.business_info.business_id,
          data_type : "outbox",
          inbox_id: [filerecover.inbox_id]
      };
      // payload = {
      //      file_id:[filerecover.file_id],
      //      account_id:this.dataAccountId,
      //      status:"trash_outbox"
      // }
      let auth = await gbfGenerate.generateToken();
     await this.axios
      // .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/recover_inbox_outbox",payload,{headers: { Authorization: auth.code },})
      .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/recovery_file_inbox_outbox",payload,{headers: { Authorization: auth.code },})
      .then(response =>{
        console.log("res",response);
          if (response.data.status === "OK") {
                Toast.fire({
                    icon: "success",
                    title: "OK"
              });
              this.loadstorage();
              this.loadfiles();
            } else {
              Toast.fire({
                icon: "error",
                     title: response.data.errorMessage
              });
            }
        })

    },
    opendialogtrue(){
      if (this.filemultipledelete.length < 1) {
            Toast.fire({
            icon: "warning",
            title: this.$t("myinboxPage.msg.nofile")
          });
        }
        else{
          // this.opendialog = true
          this.opendialogmultideleteforever = true
          console.log(this.filemultipledelete);
        }
    },
     opendialogrestore(){
        if (this.filemultipledelete.length < 1) {
            Toast.fire({
            icon: "warning",
            title: this.$t("myinboxPage.msg.nofile")
          });
        }
        else{
          this.opendialogmultirestore = true
          console.log("ไฟล์ที่จะrestore",this.filemultipledelete);
        }
    },
   async multipledelete(){
        let filedata = [];
        for (let i = 0; i < this.filemultipledelete.length; i++) {
          //  filedata.push(this.filemultipledelete[i]["file_id"]);
          //  รอ backend
          filedata.push({
            file_id:this.filemultipledelete[i]["file_id"],
            inbox_id:this.filemultipledelete[i]["inbox_id"]
            })
        }
        let payload ={
         object: filedata,
         user_id: this.dataUsername,
         account_id: this.dataAccountId
        };
        this.loaddataprogress = true;
        let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/multiple_delete_file_inbox",
            payload,{headers: { Authorization: auth.code },}
          )
          .then(response => {
            if (response.data.status === "OK") {
              this.loadstorage();
              this.loadfiles();
              this.clearmultipledelete();
              Toast.fire({
                icon: "success",
                title: this.$t("sharefile.deletesuc")
              });
              this.loaddataprogress = false;
              }
            })
          .catch(error => {
            console.log(error);
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: "response.data.errorMessage"
            });
          });
    },
    clearmultipledelete() {
      this.filemultipledelete.splice(0, this.filemultipledelete.length);
    },
    checkSort(){  
      // this.loadfolder();
      this.rootfile = this.buff;
      this.clickSort = false
      this.multiSort=false
      
    },
    sortFile(parameter){
      this.folderSort = [];
      this.fileSort = [];
      this.sortdata = [];
      this.rootfile = [];
      let i,j;
      for(i=0;i<parameter.length;i++){
        if(parameter[i].file_type === 'folder'){
           this.folderSort.push(parameter[i])
        }else(
          this.fileSort.push(parameter[i])
        )}
        if(this.sortby === 'name'){
          if(this.multiSort===true){
            this.folderSort.sort(function (a, b) {
              return a.file_name.localeCompare(b.file_name);
            });
            this.fileSort.sort(function (a, b) {
              return a.file_name.localeCompare(b.file_name);
            });
          }else{
            this.folderSort.sort(function (a, b) {
              return b.file_name.localeCompare(a.file_name);
            });
            this.fileSort.sort(function (a, b) {
              return b.file_name.localeCompare(a.file_name);
            });
          }
        }else if(this.sortby === 'date'){
          if(this.multiSort===true){
            this.folderSort.sort(function (a, b) {
              return a.file_lastdtm.localeCompare(b.file_lastdtm);
            });
            this.fileSort.sort(function (a, b) {
              return a.file_lastdtm.localeCompare(b.file_lastdtm);
            });
          }else{
            this.folderSort.sort(function (a, b) {
              return b.file_lastdtm.localeCompare(a.file_lastdtm);
            });
            this.fileSort.sort(function (a, b) {
              return b.file_lastdtm.localeCompare(a.file_lastdtm);
            });
          }
        }else if(this.sortby === 'size'){
          if(this.multiSort===true){
            this.folderSort.sort(function (a, b) {
              return b.file_size-a.file_size;
            });
            this.fileSort.sort(function (a, b) {
              return b.file_size-a.file_size;
            });
          }else{
            this.folderSort.sort(function (a, b) {
              return a.file_size-b.file_size;
            });
            this.fileSort.sort(function (a, b) {
              return a.file_size-b.file_size;
            });
          }
        }else if(this.sortby === 'owner'){
          if(this.multiSort===true){
            this.folderSort.sort(function (a, b) {
              return a.file_owner_eng.localeCompare(b.file_owner_eng);
            });
             this.folderSort.sort(function (a, b) {
              return a.file_owner_th.localeCompare(b.file_owner_th);
            });
            this.fileSort.sort(function (a, b) {
              return a.file_owner_eng.localeCompare(b.file_owner_eng);
            });
            this.fileSort.sort(function (a, b) {
              return a.file_owner_th.localeCompare(b.file_owner_th);
            });
          }else{
            this.folderSort.sort(function (a, b) {
              return b.file_owner_eng.localeCompare(a.file_owner_eng);
            });
            this.folderSort.sort(function (a, b) {
              return b.file_owner_th.localeCompare(a.file_owner_th);
            });
            this.fileSort.sort(function (a, b) {
              return b.file_owner_eng.localeCompare(a.file_owner_eng);
            });
            this.fileSort.sort(function (a, b) {
              return b.file_owner_th.localeCompare(a.file_owner_th);
            });
          }
        }
         this.sortdata.push(this.folderSort)
         this.sortdata.push(this.fileSort)
        for(i=0;i < this.sortdata.length;i++){
          for(j=0;j < this.sortdata[i].length;j++){
             this.rootfile.push(this.sortdata[i][j])
          }
        }
        return this.rootfile
        
    },
    filteredItems(search){
      this.search=search
    },
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    setformatdate(_date) {
      if (_date === "" || _date === null || _date === undefined) {
        return "-";
      } else {
        let data =
          _date.substring(8, 6) +
          "/" +
          _date.substring(6, 4) +
          "/" +
          _date.substring(0, 4);
        return data || "-";
      }
    },
    formatdatetime_doc(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear 
          // +
          // " " +
          // hour +
          // ":" +
          // minute +
          // ":" +
          // second
        );
      }
    },
    sendParentfolder() {
      if (this.$route.params.id === undefined) {
        this.parentfolder = this.account_active["directory_id"];
      } else {
        this.parentfolder = this.$route.params.id;
      }
    },   
   async setPermissionFile(parameter, status) {
      console.log("para",parameter,status);
      let auth = await gbfGenerate.generateToken();
      let api;
      let payload;
      let msgres;
      if(parameter.account_sender === ""){
          if (parameter.file_type === "folder") {
          payload = {
            account_id: this.dataAccountId,
            folder_id: parameter.file_id,
            folder_name: "",
            status_folder: status,
            move_to_id: "",
            user_id: this.dataUsername
          };
          api = "/api/v2/update_folder_id";
        } else {
          payload = {
            account_id: this.dataAccountId,
            user_id: this.dataUsername,
            file_id: parameter.file_id,
            filename: "",
            folder_id: "",
            status_file: status
          };
          api = "/api/v2/update_file_id";
        }
      this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload,{headers: { Authorization: auth.code },})
        .then(response => {
          console.log(response);
          if (response.data.status === "OK") {
            if (status === "N") {
              msgres = "กู้คืน " + parameter.file_name + " สำเร็จ";
            } else {
              msgres = "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ";
            }

            Toast.fire({
              icon: "success",
              title: msgres
            });
            // this.$emit('closecurrent')
            this.loadfolder();
            this.loadsharefolder();
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage
            });
          }
        })
        .catch(error => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
          });
        });
      }else{
         let api;
        let payload;
        let msgres;
        if (parameter.file_type === "folder") {
          payload = {
            id: parameter.file_id,
            account_id: this.dataAccountId, 
            status_folder: status
          };
          api = "/api/update_share_statusfolder";
        } else {
          payload = {
            id: parameter.file_id,
            account_id: this.dataAccountId, 
            status_file: status
          };
          api = "/api/update_share_statusfile";
        }
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload,{headers: { Authorization: auth.code },})
          .then(response => {
            console.log(response);
            if (response.data.status === "OK") {
            if (status === "N") {
              msgres = "กู้คืน " + parameter.file_name + " สำเร็จ";
            } else {
              msgres = "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ";
            }
              Toast.fire({
                icon: "success",
                title: msgres
              });
              // this.$emit('closecurrent')
            this.loadfolder();
            this.loadsharefolder();
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage
              });
            }
          })
          .catch(error => {
            console.log(error);
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
            });
          });     
      }
    },
    msgalert(file) {
      this.datadelete = file;
      this.msgConfirm = true;
    },
    callremove(file) {
      if (file["file_type"] === "folder") {
        this.deletefolder(file);
      } else {
        this.deletefile(file);
      }
    },
    async deletefile(file) {
      this.processdelete = true;
      let msg;
      // if(parameter.file_owner_eng === 'Me' && parameter.file_owner_th === 'ฉัน'){
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: file["file_id"]
        };
        console.log("payload payload payload ",payload);
        let auth = await gbfGenerate.generateToken();
        var response = await this.axios.post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_file",
          payload,{headers: { Authorization: auth.code },}
        );
        try {
          console.log(response);
          this.processdelete = false;
          this.msgConfirm = false;
          if (response.data.status === "OK") {
            msg = "ลบ " + file.file_name + " ออกถาวรสำเร็จ";
            Toast.fire({
              icon: "success",
              title: msg
            });
            this.loadstorage();
            this.loadfolder();
          } else {
            msg = "ลบ " + file.file_name + " ออกถาวรไม่สำเร็จ";
            Toast.fire({
              icon: "error",
              title: msg
            });
          }
        } catch (ex) {
          console.log(ex);
          this.processdelete = false;
          this.msgConfirm = false;
          Toast.fire({
            icon: "error",
            title: ex
          });
        // }
      }
    },
    async deletefolder(file) {
      this.processdelete = true;
      let msg;
      // if(parameter.file_owner_eng === 'Me' && parameter.file_owner_th === 'ฉัน'){
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          folder_id: file["file_id"]
        };
        console.log("payload payload payload2",payload);
        let auth = await gbfGenerate.generateToken();
        var response = await this.axios.post(
          process.env.VUE_APP_SERVICE_DELETE_FOLDER + "/api/delete_folder",
          payload,{headers: { Authorization: auth.code },}
        );
        try {
          console.log(response);
          this.processdelete = false;
          this.msgConfirm = false;
          if (response.data.status === "OK") {
            msg = "ลบ " + file.file_name + " ออกถาวรสำเร็จ";
            Toast.fire({
              icon: "success",
              title: msg
            });
            this.loadfolder();
            this.loadstorage();
          } else {
            msg = "ลบ " + file.file_name + " ออกถาวรไม่สำเร็จ";
            Toast.fire({
              icon: "error",
              title: msg
            });
          }
        } catch (ex) {
          console.log(ex);
          this.processdelete = false;
          this.msgConfirm = false;
          Toast.fire({
            icon: "error",
            title: ex
          });
        }
      // }
    },

    rightclickfileandfolder(e, filename) {
      console.log(e);
      e.preventDefault();
      this.showcurrentMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;
      this.$nextTick(() => {
        console.log(filename);
        this.currentfile = filename;
        this.showcurrentMenu = true;
      });
    },

    gotodirectory(folderid, type) {
      if (type === "folder") {
        this.$router.replace({ path: "/directory/" + folderid });
        this.loadfolder();
      }
    },

    show(e) {
      console.log(e);
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        if (this.showcurrentMenu === true) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }
      });
    },

    onDrop(files) {
      console.log(files);
    },
    delete_filter(){
      console.log("this.fromdatedocument",this.fromdatedocument);
      console.log("this.todatedocument",this.todatedocument);
      console.log("this.fromdateupload",this.fromdateupload);
      console.log("this.todateupload",this.todateupload);
      // console.log("this.sendername",this.sendername);
      // console.log("this.receivername",this.receivername);
      // console.log("this.documenttype",this.documenttype);
      // console.log("this.statusetax",this.statusetax);
      // console.log("this.filename",this.filename);
      // console.log("this.fileextension",this.fileextension);
      console.log("this.documentid",this.documentid);
      console.log("this.documentidto",this.documentidto);
      // console.log("this.sellertaxid",this.sellertaxid);
      // console.log("this.sellerbranchid",this.sellerbranchid);
      // console.log("this.buyertaxid",this.buyertaxid);
      // console.log("this.buyerbranchid",this.buyerbranchid);
      // console.log("เข้า searchdocument");
      console.log("this.filename",this.filename);
      console.log("this.fileextension",this.fileextension);
      // console.log("this.textsearchoutbox",this.textsearchoutbox);
      if (
        (this.filename === "" || this.filename === null) &&
        (this.fileextension === "" || this.fileextension === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null)  &&
        (this.receivername === "" || this.receivername === null ) &&
        this.statusonechat.length == 0 &&
        (this.sendername === "" || this.sendername === null) &&
        (this.oneboxtype.length == 0 || this.oneboxtype.length == null) &&
        this.documenttype.length == 0 &&
        (this.documentid === "" ||this.documentid === null ) &&
        (this.documentidto === "" || this.documentidto === null) &&
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null ) &&
        (this.sellertaxid === "" || this.sellertaxid === null) &&
        (this.sellerbranchid === "" || this.sellerbranchid === null) &&
        (this.buyertaxid === "" || this.buyertaxid === null)&&
        (this.buyerbranchid === "" || this.buyerbranchid === null)&&
        (this.ponumber === "" || this.ponumber === null)
      ){
        this.opendialogalertcheckdeletefilter = true;
        } else if (
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) &&
        (this.ponumber === "" || this.ponumber === null)&&
        (this.documentid === "" || this.documentid === null) &&
        (this.documentidto === "" || this.documentidto === null) )
        {
        this.opendialogalertcheckdeletefilter = true;
       
      }else if(
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) && (
        (this.documentid !== "" || this.documentid !== null) ||
        (this.documentidto !== "" || this.documentidto !== null) ||
        // (this.filename !== "" || this.filename !== null) ||
        (this.ponumber !== "" || this.ponumber !== null)
        )
      ){
        VueCookies.set("checkalertresendonetime", false);
        // this.panel = false;
        // this.getSourceSystem();
        this.opendialogcheckdeletefilter = true;
        this.getfileoutbox_trash();
      }else if(
        (this.fromdatedocument !== ""  &&
        this.todatedocument !== ""  ) ||
        (this.fromdateupload !== ""  &&
        this.todateupload !== "" )
      ){
        VueCookies.set("checkalertresendonetime", false);
        // this.panel = false;
        // this.getSourceSystem();
        this.opendialogcheckdeletefilter = true;
        this.getfileoutbox_trash();
      }else{
        VueCookies.set("checkalertresendonetime", false);
        // this.panel = false;
        // this.getSourceSystem();
        this.opendialogcheckdeletefilter = true;
        this.getfileoutbox_trash();
      }
    },
    async getfileoutbox_trash(){
      console.log("getfileoutbox...trash");
      this.type_outbox ="trashoutbox";
      // this.total_delete_file = 0;
      // let cal_limit = this.size;
      // let cal_offset = this.size * (this.page - 1);
      let payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        data_type: "outbox",
        search_type: this.textsearchoutbox === '' ? '' : 'document_no',
        search_text: this.textsearchoutbox,
        limit: "",
        offset: "",
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        filename:this.filename === null ? '' : this.filename,
        fileextension:this.fileextension,
        doc_no_start: this.documentid === null ? '' : this.documentid,
        doc_no_end: this.documentidto === null ? '' : this.documentidto,
        doc_type:this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name:this.sendername === null ? '' : this.sendername,
        sellerTaxid:this.sellertaxid === null ? '' : this.sellertaxid,
        sellerBranchid:this.sellerbranchid === null ? '' : this.sellerbranchid,
        receiver_name:this.receivername === null ? '' : this.receivername,
        receiverTaxid:this.buyertaxid === null ? '' : this.buyertaxid,
        receiverBranchid:this.buyerbranchid === null ? '' : this.buyerbranchid,
        status:this.sortStatus,
        // refund_status:this.statusetax == "Y" ? "Y" : this.statusetax == "N" ? "N" : "",
        po_number:this.ponumber === null ? '' : this.ponumber,
        customer_box: this.oneboxtype.length === 1 ? this.oneboxtype : "",
        status_onechat: this.statusonechat,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
        await this.axios
        .post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/v1/get_file_trash_inbox_outbox",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((res) => {
          if (res.data.status === "OK") {
            console.log("OK...Trash...Outbox", res);
          }

           this.total_delete_file = res.data.total;
             console.log("total_delete_file",this.total_delete_file);
           this.list_documents = res.data.data;
             console.log("list_documents",this.list_documents);            
          })
    },
    restore_filter(){
      console.log("this.fromdatedocument",this.fromdatedocument);
      console.log("this.todatedocument",this.todatedocument);
      console.log("this.fromdateupload",this.fromdateupload);
      console.log("this.todateupload",this.todateupload);
      // console.log("this.sendername",this.sendername);
      // console.log("this.receivername",this.receivername);
      // console.log("this.documenttype",this.documenttype);
      // console.log("this.statusetax",this.statusetax);
      // console.log("this.filename",this.filename);
      // console.log("this.fileextension",this.fileextension);
      console.log("this.documentid",this.documentid);
      console.log("this.documentidto",this.documentidto);
      // console.log("this.sellertaxid",this.sellertaxid);
      // console.log("this.sellerbranchid",this.sellerbranchid);
      // console.log("this.buyertaxid",this.buyertaxid);
      // console.log("this.buyerbranchid",this.buyerbranchid);
      // console.log("เข้า searchdocument");
      console.log("this.filename",this.filename);
      console.log("this.fileextension",this.fileextension);
      // console.log("this.textsearchoutbox",this.textsearchoutbox);
      if (
        (this.filename === "" || this.filename === null) &&
        (this.fileextension === "" || this.fileextension === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null)  &&
        (this.receivername === "" || this.receivername === null ) &&
        this.statusonechat.length == 0 &&
        (this.sendername === "" || this.sendername === null) &&
        (this.oneboxtype.length == 0 || this.oneboxtype.length == null) &&
        this.documenttype.length == 0 &&
        (this.documentid === "" ||this.documentid === null ) &&
        (this.documentidto === "" || this.documentidto === null) &&
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null ) &&
        (this.sellertaxid === "" || this.sellertaxid === null) &&
        (this.sellerbranchid === "" || this.sellerbranchid === null) &&
        (this.buyertaxid === "" || this.buyertaxid === null)&&
        (this.buyerbranchid === "" || this.buyerbranchid === null)&&
        (this.ponumber === "" || this.ponumber === null)
      ){
        this.opendialogalertcheckrestorefilter = true;
        } else if (
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) &&
        (this.ponumber === "" || this.ponumber === null)&&
        (this.documentid === "" || this.documentid === null) &&
        (this.documentidto === "" || this.documentidto === null) )
        {
        this.opendialogalertcheckrestorefilter = true;
       
      }else if(
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) && (
        (this.documentid !== "" || this.documentid !== null) ||
        (this.documentidto !== "" || this.documentidto !== null) ||
        // (this.filename !== "" || this.filename !== null) ||
        (this.ponumber !== "" || this.ponumber !== null)
        )
      ){
        VueCookies.set("checkalertresendonetime", false);
        // this.panel = false;
        // this.getSourceSystem();
        this.opendialogcheckrestorefilter = true;
        this.getfileoutbox_trash();
      }else if(
        (this.fromdatedocument !== ""  &&
        this.todatedocument !== ""  ) ||
        (this.fromdateupload !== ""  &&
        this.todateupload !== "" )
      ){
        VueCookies.set("checkalertresendonetime", false);
        // this.panel = false;
        // this.getSourceSystem();
        this.opendialogcheckrestorefilter = true;
        this.getfileoutbox_trash();
      }else{
        VueCookies.set("checkalertresendonetime", false);
        // this.panel = false;
        // this.getSourceSystem();
        this.opendialogcheckrestorefilter = true;
        this.getfileoutbox_trash();
      }
    },
    clear_data() {
        (this.ponumber = ""),
        (this.filename = ""),
        (this.fileextension = []),
        (this.fromdateupload = ""),
        (this.todateupload = ""),
        (this.sendername = ""),
        (this.receivername = ""),
        (this.documenttype = []),
        (this.oneboxtype = []),
        (this.statusonechat = []),
        // (this.statusetax = []),
        (this.documentid = ""),
        (this.documentidto = ""),
        (this.fromdatedocument = ""),
        (this.todatedocument = ""),
        (this.sellertaxid = ""),
        (this.sellerbranchid = ""),
        (this.buyertaxid = ""),
        (this.buyerbranchid = "");
    },
    searchdocument() {
      console.log("this.fromdatedocument",this.fromdatedocument);
      console.log("this.todatedocument",this.todatedocument);
      console.log("this.fromdateupload",this.fromdateupload);
      console.log("this.todateupload",this.todateupload);
      console.log("this.sendername",this.sendername);
      console.log("this.receivername",this.receivername);
      console.log("this.documenttype",this.documenttype);
      // console.log("this.statusetax",this.statusetax);
      console.log("this.filename",this.filename);
      console.log("this.fileextension",this.fileextension);
      console.log("this.textsearchoutbox",this.textsearchoutbox);
      // console.log("this.documentidto",this.documentidto);
      console.log("this.sellertaxid",this.sellertaxid);
      console.log("this.sellerbranchid",this.sellerbranchid);
      console.log("this.buyertaxid",this.buyertaxid);
      console.log("this.buyerbranchid",this.buyerbranchid);
      console.log("this.ponumber",this.ponumber);
      // console.log("เข้า searchdocument");
      // this.loadfiles();
      if (
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) &&
        (this.sendername === "" || this.sendername === null) &&
        (this.receivername === "" || this.receivername === null) &&
        this.documenttype.length == 0 &&
        this.statusonechat.length == 0 &&
        (this.oneboxtype.length == 0 || this.oneboxtype.length == null) &&
        (this.filename === "" || this.filename === null) &&
        this.fileextension.length == 0 &&
        // (this.textsearchoutbox === "" || this.textsearchoutbox === null) &&
        (this.ponumber === "" || this.ponumber === null) &&
        (this.documentid === "" || this.documentid === null) &&
        (this.documentidto === "" || this.documentidto === null) &&
        (this.sellertaxid === "" || this.sellertaxid === null) &&
        (this.sellerbranchid === "" || this.sellerbranchid === null) &&
        (this.buyertaxid === "" || this.buyertaxid === null) &&
        (this.buyerbranchid === "" || this.buyerbranchid === null)
      ){
        console.log("get = 1");
        // this.getSourceSystem_trash();
         this.opendialogalersearchdonotfillout = true;
        } else if (
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) &&
        (this.documentid === "" || this.documentid === null) &&
        (this.ponumber === "" || this.ponumber === null)
        ){
        console.log("get = 2");
        this.opendialogalersearchdonotfillout = true;
       
      // }else if(
      //   (this.fromdatedocument === "" || this.fromdatedocument === null) &&
      //   (this.todatedocument === "" || this.todatedocument === null) &&
      //   (this.fromdateupload === "" || this.fromdateupload === null) &&
      //   (this.todateupload === "" || this.todateupload === null)
      // ){
      //   VueCookies.set("checkalertresendonetime", false);
      //   this.panel = false;
      //   console.log("get = 1");
      //   this.getSourceSystem_trash();
      // }else if(
      //   (this.fromdatedocument !== ""  &&
      //   this.todatedocument !== ""  ) ||
      //   (this.fromdateupload !== ""  &&
      //   this.todateupload !== "" )
      // ){
      //   VueCookies.set("checkalertresendonetime", false);
      //   this.panel = false;
      //   console.log("get = 2");
      //   this.getSourceSystem_trash();
      }else{
        VueCookies.set("checkalertresendonetime", false);
        this.panel = false;
        console.log("get = 3");
        this.getSourceSystem_trash();
      }
    },
    async getSourceSystem_trash(){
      console.log("get_file_trash.....ok");
      this.file = [];
      this.rootfile = [];
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        data_type: "outbox",
        search_type: this.textsearchoutbox === '' ? '' : 'document_no',
        search_text: this.textsearchoutbox,
        limit: cal_limit,
        offset: cal_offset,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        filename:this.filename === null ? '' : this.filename,
        fileextension:this.fileextension,
        doc_no_start: this.documentid === null ? '' : this.documentid,
        doc_no_end: this.documentidto === null ? '' : this.documentidto,
        doc_type:this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name:this.sendername === null ? '' : this.sendername,
        sellerTaxid:this.sellertaxid === null ? '' : this.sellertaxid,
        sellerBranchid:this.sellerbranchid === null ? '' : this.sellerbranchid,
        receiver_name:this.receivername === null ? '' : this.receivername,
        receiverTaxid:this.buyertaxid === null ? '' : this.buyertaxid,
        receiverBranchid:this.buyerbranchid === null ? '' : this.buyerbranchid,
        status:this.sortStatus,
        // refund_status:this.statusetax == "Y" ? "Y" : this.statusetax == "N" ? "N" : "",
        po_number:this.ponumber === null ? '' : this.ponumber,
        customer_box: this.oneboxtype.length === 1 ? this.oneboxtype : "",
        status_onechat: this.statusonechat,
        sort_by: this.sort_by,
        direction: this.direction,
      };
      console.log("payload", payload);
      this.loaddataprogress = true;
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v1/get_file_trash_inbox_outbox",
          // process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v2/search_file",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response.data.data);
            this.loaddataprogress = false;
            for (let i = 0; i < response.data.data.length; i++) {
              if (
                response.data.data[i]["filename"] !== null ||
                response.data.data[i]["filename"] === ""
              ) {
                let datafolder = {};
                // let typefile = response.data.data[i]["filename"].split(".");
                let typefile =
                  response.data.data[i]["filename"] === null ||
                  response.data.data[i]["filename"] === ""
                    ? [response.data.data[i]["filename"], ""]
                    : response.data.data[i]["filename"].split(".");
                datafolder["file_name"] = response.data.data[i]["filename"];
                datafolder["file_document_no"] = response.data.data[i]["document_no"];
                datafolder["file_document_date"] = response.data.data[i]["document_date"];
                datafolder["file_trash_dtm"] = response.data.data[i]["trash_dtm"];
                datafolder["file_size"] = response.data.data[i]["size_file"];
                datafolder["file_document_type"] = response.data.data[i]["document_type"];
                datafolder["file_data_type"] = response.data.data[i]["data_type"];
                datafolder["file_id"] = response.data.data[i]["file_id"];
                datafolder["inbox_id"] = response.data.data[i]["inbox_id"];
                datafolder["po_number"] = response.data.data[i]["po_number"];
                datafolder["file_type"] = typefile[typefile.length - 1];
                datafolder["file_icon"] = this.seticon_new(
                  typefile[typefile.length - 1]
                );
                this.rootfile.push(datafolder);
                this.buff.push(datafolder);
                this.file.push(datafolder);
              }
            } this.loaddataprogress = false;
              this.countfile = response.data.total
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
   async loadfiles() {
      this.type_outbox ="trashoutbox";
      this.file = [];
      this.rootfile = [];
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      var payload = {
        business_id: this.dataAccountActive.type === 'Business' ? this.dataAccountActive.business_info.business_id : this.dataAccountId,
        data_type: "outbox",
        search_type: this.textsearchoutbox === '' ? '' : 'document_no',
        search_text: this.textsearchoutbox,
        limit: cal_limit,
        offset: cal_offset,
      };
      // var payload = {
      //   user_id: this.dataUsername,
      //   account_id: this.dataAccountId,
      //   folder_id: "",
      //   status_file:"TO"
      // };
       this.loaddataprogress = true;
       let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v1/get_file_trash_inbox_outbox",
          // process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v2/search_file",
          payload,{
            headers: { Authorization: auth.code },}
        )
        .then(response => {
          if (response.data.status === "OK") {
            for(let i= 0 ; i < response.data.data.length;i++){
              if(response.data.data[i]["filename"] !== null || response.data.data[i]["filename"] === ''){
                 let datafolder = {}
                // let typefile = response.data.data[i]["filename"].split(".");
                let typefile =
                  response.data.data[i]["filename"] === null ||
                  response.data.data[i]["filename"] === ""
                    ? [response.data.data[i]["filename"], ""]
                    : response.data.data[i]["filename"].split(".");
                datafolder["file_name"] = response.data.data[i]["filename"];
                datafolder["file_document_no"] = response.data.data[i]["document_no"];
                datafolder["file_document_date"] = response.data.data[i]["document_date"];
                datafolder["file_trash_dtm"] = response.data.data[i]["trash_dtm"];
                datafolder["file_size"] = response.data.data[i]["size_file"];
                datafolder["file_document_type"] = response.data.data[i]["document_type"];

                datafolder["file_id"] = response.data.data[i]["file_id"];
                datafolder["inbox_id"] = response.data.data[i]["inbox_id"];
                datafolder["po_number"] = response.data.data[i]["po_number"];
                // datafolder["inbox_id"] = response.data.data[i]["inbox_id"];
                datafolder["file_type"] = typefile[typefile.length - 1];
                datafolder["file_icon"] = this.seticon_new(
                  typefile[typefile.length - 1]
                  // let typefile = response.data.data[i]["filename"] === null || response.data.data[i]["filename"] === '' ? [response.data.data[i]["filename"],""]:response.data.data[i]["filename"].split(".");
                  // datafolder["file_name"] = response.data.data[i]["filename"];
                  // datafolder["file_id"] = response.data.data[i]["id"];
                  // datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
                  // datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
                  // datafolder["file_owner_th"] = response.data.data[i]["name_th"];
                  // datafolder["inbox_id"] = response.data.data[i]["inbox_id"];
                  // datafolder["file_size"] = response.data.data[i]["size_file"];
                  // datafolder["status_file"] = response.data.data[i]["status_file"];
                  // datafolder["status_share"] = response.data.data[i]["status_share"];
                  // datafolder["status_share_link"] = response.data.data[i]["status_share_link"];
                  // datafolder["user_id"] = response.data.data[i]["user_id"];
                  // datafolder["permission_department_setting"] = response.data.data[i]["permission_department_setting"];
                  // datafolder["permission_setting"] = response.data.data[i]["permission_setting"];
                  // datafolder["permission_account"] = response.data.data[i]["permission_account"];
                  // datafolder["file_type"] = typefile[typefile.length - 1];
                  // datafolder["file_icon"] = this.seticon_new(
                  // typefile[typefile.length - 1]
                );
                  this.rootfile.push(datafolder);
                  this.buff.push(datafolder);
                  this.file.push(datafolder)
                }
            }
            this.loaddataprogress = false;
            this.countfile = response.data.total
            console.log("this.countfile",this.countfile);
            // this.countfile = response.data.data.length
          }
        })
        .catch(error => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้"
          });
        });
    },
    seticon(parameter) {
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word-outline", "primary"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
      else {
        dataicon = ["mdi-file-outline", "black"];
      }

      return dataicon;
    },
    seticon_new(parameter) {
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "green"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "primary"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
        dataicon = ["mdi-file-image", "blue-grey"];
      } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link","pink"]
    }
      else {
        dataicon = ["mdi-file", "black"];
      }

      return dataicon;
    },

    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },
    formatDatefromdoc(datefrom,docto) {
      console.log("datefrom1",datefrom);
      console.log("dateto2",docto);
      if((!datefrom || datefrom === undefined)&& docto){
        console.log('1');
        if(docto){
          console.log('2');
          console.log("docto", docto);
          var date = new Date(docto);
          date.setDate(date.getDate() - 90);
          console.log("date",date);
          this.fromdatedocument = new Date(date).toISOString().substr(0, 10);
          console.log("this.fromdatedocument",this.fromdatedocument);
          const [year, month, day] = this.fromdatedocument.split("-");
          return `${year}${month}${day}`;
        }
      }else if(datefrom && docto){
        console.log('3');
        const [year, month, day] = datefrom.split("-");
        return `${year}${month}${day}`;
      }else if((!datefrom || datefrom === undefined ) && (!docto || docto === undefined)){
        return ""
      }else if(datefrom && (!docto || docto === undefined)){
        console.log('2');
          console.log("datefrom-", datefrom);
          var dateNow = new Date();
          var date = new Date(datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.fromdatedocument =new Date(datefrom).toISOString().substr(0, 10);
            this.todatedocument = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.fromdatedocument.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.fromdatedocument =new Date(datefrom).toISOString().substr(0, 10);
            this.todatedocument = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.fromdatedocument.split("-");
            return `${year}${month}${day}`;
          }
      }
    },
    formatDatetodoc(datefrom,docto) {
      console.log("datefrom",datefrom);
      console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
      if(!docto && datefrom){
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.todatedocument = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.todatedocument.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.todatedocument = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.todatedocument.split("-");
            return `${year}${month}${day}`;
          }
        }
      }else if(docto && datefrom){
        console.log('3');
        const [year, month, day] = docto.split("-");
        return `${year}${month}${day}`;
      }else if(!docto && !datefrom){
        return ""
      }
    },
      formatDatetodoc2(datefrom) {
      console.log("datefrom",datefrom);
      // console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            let todate = new Date().toISOString().substr(0, 10);
            // this.todatedocument = new Date().toISOString().substr(0, 10);
            const [year, month, day] = todate.split("-");
            return `${year}-${month}-${day}`;
          }else{
            console.log('4');
            let todate = new Date(date).toISOString().substr(0, 10);
            // this.todatedocument = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = todate.split("-");
            return `${year}-${month}-${day}`;
          }
        }
    },
    formatDatefromupload(datefrom,docto) {
      console.log("date",datefrom);
      console.log("date",docto);
      if(!datefrom){
        console.log('1');
        if(docto){
          console.log('2');
          console.log("docto", docto);
          var date = new Date(docto);
          date.setDate(date.getDate() - 90);
          console.log("date",date);
          this.fromdateupload = new Date(date).toISOString().substr(0, 10);
          console.log("this.fromdateupload",this.fromdateupload);
          const [year, month, day] = this.fromdateupload.split("-");
          return `${year}${month}${day}`;
        }
      }else{
        console.log('3');
        const [year, month, day] = datefrom.split("-");
        return `${year}${month}${day}`;
      }
    },
    formatDatetoupload(datefrom,docto) {
      console.log("datefrom",datefrom);
      console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
      if(!docto && datefrom){
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.todateupload = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.todateupload.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.todateupload = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.todateupload.split("-");
            return `${year}${month}${day}`;
          }
        }
      }else if(docto && datefrom){
        console.log('3');
        const [year, month, day] = docto.split("-");
        return `${year}${month}${day}`;
      }else if(!docto && !datefrom){
        return ""
      }
    },

    loadstorage() {
      this.$store.dispatch("check_storage", this.dataAccountId).then(res => {});
    },
    selectItemTable(item) {
      return this.selectColor(item, "table")
        ? "background-color:#D6EAF8;"
        : "opacity: unset;";
    },
    selectColor(e, type) {
      if (type === "table") {
        if (this.arrayfileidselect.length < 1) {
        } else {
          if (this.arrayfileidselect.filter(item => item === e).length > 0) {
            return "color:#2471A3";
          }
        }
      } else {
        if (this.arrayfileidselect.length < 1) {
        } else {
          if (this.arrayfileidselect.filter(item => item === e).length > 0) {
            return "color:#2471A3";
          }
        }
      }
    },
    setSelectitemtable(file) {
      console.log('file',file)
      this.arrayfileidselect = [];
      if (file.file_id) {
        this.arrayfileidselect = [];
        if (this.statusmutipledelete === false) {
          this.arrayfileidselect = [file.file_id]
        }
      }
    },
  },
  mounted() {
    var self = this;
    self.loadfiles();
    // self.loadsharefolder();
    // เซตค่า เอกสารลงวันที่ย้อนหลัง3เดือนจากวันที่ปัจจุบัน
    this.fromdatedocument = this.setdate_(format(new Date(), "yyyy-MM-dd"));
    this.todatedocument = format(new Date(), "yyyy-MM-dd");
  },
  created() {}
};
</script>

<style>
#demo {
  width: 100%;
  height: 100%;
}

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
}

#dropzone {
  min-height: 100%;
}

#thiscontainer_trashoutbox {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
 
}
.pointer {cursor: pointer;}
</style>